/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useOrders } from '../../../hooks/useOrders';
import { useUser } from '../../../context/UserContext';
import { HiShieldExclamation, HiShieldCheck } from 'react-icons/hi';
import { OrderStatus, OrderType } from '../../../context/types/Orders';
import * as S from './styled';
import Select from '../../Atons/Select';

const OrderTable: React.FC = () => {
  const { getUserId } = useUser();
  const { fetchOrders, orders, loading } = useOrders();
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState<OrderStatus | ''>('');
  const userId = getUserId();

  useEffect(() => {
    if (userId) {
      fetchOrders({ status, page, limit: 10 });
    }
  }, [userId, status, page]);

  const renderPageButtons = () => {
    const totalPages = Math.ceil(orders.length / 10);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <S.PageButton key={i} isActive={i === page} onClick={() => setPage(i)}>
          {i}
        </S.PageButton>
      );
    }
    return pages;
  };

  return (
    <>
      <S.Header>
        <S.Title>Financeiro</S.Title>
        <S.HeaderItem>
          <S.HeaderItemTitle>
            <HiShieldCheck /> Status
          </S.HeaderItemTitle>
          <Select
            options={[
              { value: '', label: 'Todos' },
              { value: OrderStatus.Pending, label: 'Pendente' },
              { value: OrderStatus.Completed, label: 'Completo' },
              { value: OrderStatus.Cancelled, label: 'Cancelado' }
            ]}
            value={status}
            onChange={(value: any) => setStatus(value as OrderStatus)}
            placeholder="Selecione o status"
          />
        </S.HeaderItem>
      </S.Header>
      {loading ? (
        <div>Carregando...</div>
      ) : orders.length > 0 ? (
        <S.TableContainer>
          <S.Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Tipo</th>
                <th>Valor</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{OrderType[order.type]}</td>
                  <td>R$ {order.amount.toFixed(2)}</td>
                  <td>
                    {order.status === OrderStatus.Completed ? (
                      <>
                        <HiShieldCheck /> Completo
                      </>
                    ) : order.status === OrderStatus.Pending ? (
                      <>
                        <HiShieldExclamation /> Pendente
                      </>
                    ) : (
                      <>
                        <HiShieldExclamation /> Cancelado
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </S.Table>
          <S.Pagination>
            <S.PageButton
              isActive={false}
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              Anterior
            </S.PageButton>
            {renderPageButtons()}
            <S.PageButton
              isActive={false}
              onClick={() => setPage(page + 1)}
              disabled={page === Math.ceil(orders.length / 10)}
            >
              Próximo
            </S.PageButton>
          </S.Pagination>
        </S.TableContainer>
      ) : (
        <div>Nenhuma ordem encontrada!</div>
      )}
    </>
  );
};

export default OrderTable;
