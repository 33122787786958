import styled, { css } from 'styled-components';
import { BiRefresh } from 'react-icons/bi';

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 50%;
    margin: 1rem 0;
  }
`;

interface ContainerProps {
  src: string;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.colors.primary};
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: top center;
  overflow-x: hidden;
  position: fixed;
`;

export const Wrapper = styled.form`
  width: 92%;
  max-width: 360px;
  min-height: 244.59px;
  padding: 0.5rem;

  background-color: #00000090;
  box-shadow: ${(props) =>
    css`
      ${props.theme.colors.shadow} 0px 2px 8px
    `};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.primary};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  input {
    background: 0;
    border: 0;
    outline: none;
    font-size: 1em;
    transition: padding 0.3s 0.2s ease;
    width: 100%;
    height: 48px;
    padding-left: 0.5rem;
    box-sizing: border-box;
    background-color: #f1f1f1;
    border-radius: 0.5rem;
    margin: 0.5rem 0;

    &:focus {
      padding-bottom: 0px;
    }

    &:focus + .line {
      &:after {
        transform: scaleX(1);
      }
    }
  }
`;

export const IconReloadSvg = styled(BiRefresh)`
  position: absolute;
  z-index: 1;
  color: #000;
  right: 0;
  bottom: 10px;
  font-size: 1.5rem;
  border-radius: 0rem !important;
  color: ${(props) => props.theme.colors.black};
  cursor: pointer;
  transition: 0.3s;
`;

export const WrapperSvg = styled.div`
  width: 100%;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  svg {
    border-radius: 0.5rem;
    max-width: 100%;
  }
`;

export const ImgLogo = styled.img`
  width: 44%;
  max-width: 300px;
  margin-bottom: 1rem;
`;
