import React from 'react';
import * as S from './styled';

interface ContainerProps {
  children: React.ReactNode;
  fixed?: string;
  isgame?: string;
}

export const Container = ({ children, fixed, isgame }: ContainerProps) => {
  return (
    <S.Container fixed={fixed} isgame={isgame}>
      {children}
    </S.Container>
  );
};

export const ContainerGame = ({ children, fixed, isgame }: ContainerProps) => {
  return (
    <S.ContainerGame fixed={fixed} isgame={isgame}>
      {children}
    </S.ContainerGame>
  );
};
