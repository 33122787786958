import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  value: string | string[];
  onChange: (value: string | string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  chevronIcon?: React.ReactNode;
  ariaLabel?: string;
  ariaLabelledBy?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const SelectedValue = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 48px;
  line-height: 48px;
  padding-left: 0.5rem;
  font-size: 16px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.black};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: border-color 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.black};
  font-weight: 800;
  border-radius: 0.5rem;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f9f9f9;
  }
`;

const OptionsList = styled.div<{ open: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.black};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 10;
  display: ${(props) => (props.open ? 'block' : 'none')};
  border-radius: 0.5rem;
  max-height: 200px;
`;

const OptionItem = styled.div<{ selected: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.primary : 'transparent'};
  color: ${(props) => props.theme.colors.black};
  font-weight: ${(props) => (props.selected ? 800 : 400)};
  transition: 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.black};
  }
`;

const DefaultChevronIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 12px;
  color: ${(props) => props.theme.colors.black};
`;

const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  disabled,
  multiple = false,
  chevronIcon,
  ariaLabel,
  ariaLabelledBy,
  onFocus,
  onBlur,
  onKeyDown
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (optionValue: string) => {
    if (multiple) {
      const newValue = Array.isArray(value) ? [...value] : [];
      const index = newValue.indexOf(optionValue);
      if (index > -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(optionValue);
      }
      onChange(newValue);
    } else {
      onChange(optionValue);
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderSelectedValue = () => {
    if (multiple && Array.isArray(value)) {
      return (
        options
          .filter((option) => value.includes(option.value))
          .map((option) => option.label)
          .join(', ') || placeholder
      );
    } else {
      const selectedOption = options.find((option) => option.value === value);
      return selectedOption ? selectedOption.label : placeholder;
    }
  };

  return (
    <SelectWrapper ref={wrapperRef}>
      <SelectedValue
        onClick={handleToggle}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        disabled={disabled}
        tabIndex={0}
      >
        {renderSelectedValue()}
        {!multiple &&
          (chevronIcon ? (
            chevronIcon
          ) : (
            <DefaultChevronIcon>▼</DefaultChevronIcon>
          ))}
      </SelectedValue>
      <OptionsList open={isOpen}>
        {options.map((option) => (
          <OptionItem
            key={option.value}
            onClick={() => handleOptionClick(option.value)}
            selected={
              multiple
                ? Array.isArray(value) && value.includes(option.value)
                : value === option.value
            }
          >
            {option.label}
          </OptionItem>
        ))}
      </OptionsList>
    </SelectWrapper>
  );
};

export default Select;
