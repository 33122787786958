import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SelectWrapper = styled.div`
  margin-bottom: 20px;
`;

export const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

interface LabelStyleProps {
  type?: 'primary' | 'secondary' | 'error';
}

export const Label = styled.label<LabelStyleProps>`
  width: 100%;
  display: block;
  margin-bottom: 10px;
  padding: 0.5rem 0.5rem;
  color: ${(props) => props.theme.colors.black};
  border-radius: 0.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) =>
    props.type === 'primary' &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
    `};

  ${(props) =>
    props.type === 'secondary' &&
    css`
      background-color: ${(props) => props.theme.colors.secondary};
    `};
  ${(props) =>
    props.type === 'error' &&
    css`
      background-color: #e50000;
      color: ${(props) => props.theme.colors.white};
    `};
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  width: 100%;

  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Cruyff Sans Expanded';

  margin: 1rem 0;
`;
