import React from 'react';

import ScrollToTop from './components/Atons/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';
import Theme from './style/Theme';
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import { LoadingProvider } from './context/LoadingContext';
import { CsrfProvider } from './context/CsrfContext';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './AppRoutes';
import { UserProvider } from './context/UserContext';
import { OrderProvider } from './context/OrderContext';
import { MatchProvider } from './context/MatchContext';
import { AudioProvider } from './context/AudioContext';
import { themeLight } from './style/Themes';

function App() {
  return (
    <Theme theme={themeLight}>
      <LoadingProvider>
        <BrowserRouter>
          <ToastProvider>
            <CsrfProvider>
              <UserProvider>
                <AuthProvider>
                  <OrderProvider>
                    <MatchProvider>
                      <AudioProvider>
                        <ScrollToTop />
                        <AppRoutes />
                      </AudioProvider>
                    </MatchProvider>
                  </OrderProvider>
                </AuthProvider>
              </UserProvider>
            </CsrfProvider>
          </ToastProvider>
        </BrowserRouter>
      </LoadingProvider>
      <ToastContainer />
    </Theme>
  );
}

export default App;
