/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { useOrders } from '../../../hooks/useOrders';
import { usePixPayment } from '../../../hooks/usePixPayment';
import { useUser } from '../../../context/UserContext';
import { OrderStatus } from '../../../context/types/Orders';
import {
  validateEmail,
  validateCPF,
  unmaskPhoneNumber
} from '../../../utils/validation';
import * as S from './style';
import { useToast } from '../../../context/ToastContext';
import Button from '../../Atons/Button';
import Timer from '../../Molecules/Timer';
import CopyInput from '../../Atons/CopyInput';
import Input from '../../Atons/Input';
import Title from '../../Molecules/Title';
import { debounce } from 'lodash';
import useNavigateWithLoading from '../../../hooks/useNavigateWithLoading';

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 10000;

const CreateOrderForm: React.FC = () => {
  const { createOrder } = useOrders();
  const { createPixPayment } = usePixPayment();
  const { user } = useUser();

  const [userName, setUserName] = useState<string>('');
  const [userDocument, setUserDocument] = useState<string>('');
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [amount, setAmount] = useState<string>('0,00');
  const [qrCode, setQrCode] = useState<string>('');
  const [paymentCode, setPaymentCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { notifyError, notifySuccess } = useToast();
  const [isTimerVisible, setIsTimerVisible] = useState<boolean>(true);
  const navigateWithLoading = useNavigateWithLoading();

  const handleTimeout = () => {
    setIsTimerVisible(false);
    notifyError('O tempo acabou!');
    navigateWithLoading('/jogue-agora');
  };

  useEffect(() => {
    if (user) {
      setUserName(user.username || '');
      setUserId(user.userId || '');
      setUserEmail(user.email || '');
      setUserDocument(user.document || '');
      setUserPhoneNumber(user.phone || '');
    }
  }, [user]);

  const validateAmount = (amountValue: string) => {
    const cleanedAmount = amountValue.replace(/\./g, '').replace(',', '.');
    const numericAmount = parseFloat(cleanedAmount);

    if (isNaN(numericAmount)) return false;

    return numericAmount >= MIN_AMOUNT && numericAmount <= MAX_AMOUNT;
  };

  const handleSetValueBet = (value: string) => {
    setAmount(value);
  };

  const handleConfirmPayment = () => {
    navigateWithLoading('/jogue-agora');
  };

  const handleCreateOrder = useCallback(async () => {
    setLoading(true);

    if (
      !userId ||
      !userName ||
      !userDocument ||
      !userPhoneNumber ||
      !userEmail ||
      !amount
    ) {
      notifyError('Todos os campos são obrigatórios.');
      setLoading(false);
      return;
    }

    if (!validateEmail(userEmail)) {
      notifyError('E-mail inválido.');
      setLoading(false);
      return;
    }

    if (!validateCPF(userDocument)) {
      notifyError('CPF inválido.');
      setLoading(false);
      return;
    }

    if (!validateAmount(amount)) {
      notifyError(`O valor deve estar entre ${MIN_AMOUNT} e ${MAX_AMOUNT}.`);
      setLoading(false);
      return;
    }

    try {
      const order = await createOrder({
        userId: userId,
        amount: Number(amount.replace('.', '').replace(',', '.')),
        status: OrderStatus.Pending,
        type: 'PAYMENT', // ou 'TRANSFER' dependendo da lógica
        approvalStatus: 'PENDING'
      });

      const { paymentCodeBase64, paymentCode } = await createPixPayment({
        id: order.id,
        requestNumber: `${order.id}`,
        dueDate: new Date().toISOString(),
        amount: Number(amount.replace(/\./g, '').replace(',', '.')),
        shippingAmount: 0.0,
        discountAmount: 0.0,
        usernameCheckout: 'checkout',
        callbackUrl: `${process.env.REACT_APP_API_URL}/order/webhook/suitpay`,
        client: {
          name: userName,
          document: userDocument,
          phoneNumber: unmaskPhoneNumber(userPhoneNumber),
          email: userEmail
        }
      });

      notifySuccess('Pedido realizado com sucesso!');
      setQrCode(paymentCodeBase64);
      setPaymentCode(paymentCode);
      setAmount('');
      setLoading(false);
    } catch (error) {
      notifyError(
        'Erro ao criar o pedido ou pagamento Pix. Por favor, tente novamente mais tarde.'
      );
      console.error('Erro:', error);
      setLoading(false);
    }
  }, [
    userId,
    amount,
    userName,
    userDocument,
    userPhoneNumber,
    userEmail,
    createOrder,
    createPixPayment,
    notifyError,
    notifySuccess
  ]);

  useEffect(() => {
    const observer = new ResizeObserver(
      debounce((entries) => {
        entries.forEach((entry: { target: any }) => {});
      }, 300)
    );

    observer.observe(document.body);

    return () => {
      observer.disconnect();
    };
  }, []);

  if (user === undefined) {
    return (
      <S.FormContainer>Carregando informações do usuário...</S.FormContainer>
    );
  }

  return (
    <S.FormContainer>
      <Title text="Depositar" />
      {loading ? (
        <S.LoadingContainer>
          <S.LoadingSpinner />
        </S.LoadingContainer>
      ) : (
        <>
          {!qrCode && (
            <>
              <Input
                hidden
                type="text"
                placeholder="Seu Nome"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <Input
                hidden
                type="cpf"
                placeholder="CPF"
                value={userDocument}
                onChange={(e) => setUserDocument(e.target.value)}
              />
              <Input
                hidden
                type="phone"
                placeholder="Telefone"
                value={userPhoneNumber}
                onChange={(e) => setUserPhoneNumber(e.target.value)}
              />
              <Input
                hidden
                type="email"
                placeholder="Email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <Input
                type="currency"
                placeholder="Digite ou selecione o valor"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <S.Warning type="bônus">
                Ao depositar um valor maior que <span>R$50,00</span> você
                receberá 5 Rodadas Grátis com multiplicação em dobro.
              </S.Warning>
              <S.Warning type="button">
                <button onClick={() => handleSetValueBet('20,00')}>
                  R$ 20,00
                </button>
                <button onClick={() => handleSetValueBet('30,00')}>
                  R$ 30,00
                </button>
                <button onClick={() => handleSetValueBet('50,00')}>
                  R$ 50,00
                </button>
                <button onClick={() => handleSetValueBet('100,00')}>
                  R$ 100,00
                </button>
              </S.Warning>
              <S.Warning type="warning">
                <span>Depósito mínimo : R$1.00</span>
                <span>Depósito máximo : R$10000.00</span>
              </S.Warning>
              <Button
                variant={'red'}
                loading={loading ? 'true' : undefined}
                onClick={handleCreateOrder}
              >
                Depositar
              </Button>
            </>
          )}
          <S.ConfirmPayment>
            {qrCode && (
              <S.SuccessMessage>
                <img
                  src={`data:image/png;base64,${qrCode}`}
                  alt="QR Code para pagamento"
                />
              </S.SuccessMessage>
            )}
            {qrCode && isTimerVisible && <Timer onTimeout={handleTimeout} />}
            {paymentCode && (
              <S.Warning type="bônus">
                <p>Código de Pagamento:</p>
                <CopyInput value={paymentCode} />
              </S.Warning>
            )}
            {paymentCode && (
              <>
                <Button
                  loading={loading ? 'true' : undefined}
                  onClick={handleConfirmPayment}
                >
                  Confirmar Pagamento!
                </Button>
                <S.Warning type="warning">
                  Ao clicar em depositar, abra o aplicativo do seu banco e
                  escaneie o QR Code que aparecerá na tela, confira e confirme o
                  valor do depósito e faça o pagamento. Os depósitos podem levar
                  até 1 minuto para serem creditados a sua conta do Embaixadinha
                  Premiada
                </S.Warning>
              </>
            )}
          </S.ConfirmPayment>
        </>
      )}
    </S.FormContainer>
  );
};

export default CreateOrderForm;
