// styled.ts
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

export const afterRecord = keyframes`
  0% {
    transform: translate(-50%, 10%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 10%) scale(5);
    opacity: 0;
    color: white;
  }
`;

export const Loader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 35vh;
  font-size: 2rem;
  top: 0;
  left: 0;
  z-index: 999;
  background: #00000080;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  transition: 500ms;
`;

export const GameDiv = styled.div`
  opacity: 0;
  width: 100%;
  max-height: 85vh;
  min-height: 85vh;
  display: block;
  background: #00000050;
  position: relative;
  overflow: hidden;
`;

export const ContainerUI = styled.div`
  position: absolute;
  right: 1rem;
`;

export const ChancesBoard = styled.div`
  width: 90px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: #00000050;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
`;

export const Ball = styled.div`
  position: absolute;
  width: 90px;
  height: 90px;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  cursor: pointer;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 10;
  overflow: hidden;
`;

export const InnerBall = styled.div`
  background-image: url('./game/football.png');
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const BallShadow = styled.div`
  background: rgba(102, 102, 102, 0.562);
  position: absolute;
  transform: translate(-50%, 50%);
  border-radius: 100%;
  height: 10px;
  bottom: 0;
`;

export const ScoreBoard = styled.div`
  width: 90px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: #00000050;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
`;

export const RecordBoard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  cursor: default;
  transition: 800ms;
  font-size: 200px;
  color: ${(props) => props.theme.colors.white};
  transform: translate(-50%, -50%);
  z-index: 0;
  &.recordmode {
    color: ${(props) => props.theme.colors.primary};
    text-shadow: 0px 0px 20px ${(props) => props.theme.colors.primary};
  }
`;

export const RecordBoardBehind = styled.div`
  animation: ${afterRecord} 800ms ease-out;
  opacity: 0;
  transform: translate(-50%, -10%);
`;

export const HowTo = styled.span`
  text-align: center;
  display: block;
  color: black;
  font-size: 1.5rem;
`;

export const BottomGame = styled.div`
  width: 100vw;
  height: 8vh;
  background: #000;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
`;

export const LinkHome = styled(Link)`
  width: 100%;
  max-width: 280px;
  height: 48px;

  background: ${(props) => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0.5rem 0;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: 0.3s;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }
`;
