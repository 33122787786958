import React from 'react';
import { OrderProvider } from '../../../context/OrderContext';
import { Container } from '../../Atons/Container';
import Navbar from '../../Molecules/Navbar';
import FinancialTable from '../../Molecules/FinancialTable';

import * as S from './styled';

const FinancialPage = () => {
  return (
    <OrderProvider>
      <Container>
        <Navbar />
        <S.Wrapper>
          <FinancialTable />
        </S.Wrapper>
      </Container>
    </OrderProvider>
  );
};

export default FinancialPage;
