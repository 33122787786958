/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useCallback, useMemo, ChangeEvent } from 'react';
import * as S from './styled';

interface StyledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type:
    | 'text'
    | 'email'
    | 'password'
    | 'cpf'
    | 'cnpj'
    | 'phone'
    | 'randomKey'
    | 'qrCode'
    | 'currency';
  placeholder?: string;
  value: number | string;
  readOnly?: boolean;
  autocomplete?: string;
}

type MaskFunction = (value: string) => string;

const Input: React.FC<StyledInputProps> = ({
  type,
  placeholder,
  value,
  onChange,
  autocomplete,
  readOnly = false,
  ...rest
}) => {
  const [error, setError] = useState<string>('');

  const regexValidators = useMemo(
    () => ({
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      password: /^.{6,}$/,
      text: /^[a-zA-Z0-9 ]+$/,
      cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
      cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
      phone: /^\(\d{2}\) \d{4,5}-\d{4}$/,
      randomKey: /^[a-zA-Z0-9]{32}$/,
      qrCode: /^([A-Za-z0-9+/=]{40,})$/,
      currency: /^\d{1,3}(?:\.\d{3})*(?:,\d{2})?$|^\d+(?:,\d{2})?$/
    }),
    []
  );

  const maskMap: Partial<Record<StyledInputProps['type'], MaskFunction>> =
    useMemo(
      () => ({
        cpf: (value: string) =>
          value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})$/, '$1-$2'),
        cnpj: (value: string) =>
          value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2'),
        phone: (value: string) =>
          value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d{1,4})$/, '$1-$2'),
        currency: (value: string) => {
          const numValue = parseFloat(value.replace(/[^0-9]/g, '')) / 100;
          return numValue
            .toFixed(2)
            .replace('.', ',')
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
      }),
      []
    );

  const validateInput = useCallback(
    (value: string) => regexValidators[type]?.test(value) ?? true,
    [regexValidators, type]
  );

  const handleBlur = () => {
    if (!validateInput(value.toString())) {
      setError(getErrorMessage(type));
    } else {
      setError('');
    }
  };

  const getErrorMessage = (type: string) => {
    switch (type) {
      case 'email':
        return 'Endereço de email inválido';
      case 'password':
        return 'A senha deve ter pelo menos 6 caracteres';
      case 'text':
        return 'Username só pode conter letras e números';
      case 'cpf':
        return 'CPF inválido. Formato esperado: xxx.xxx.xxx-xx';
      case 'cnpj':
        return 'CNPJ inválido. Formato esperado: xx.xxx.xxx/xxxx-xx';
      case 'phone':
        return 'Número de telefone inválido. Formato esperado: (xx) xxxxx-xxxx';
      case 'randomKey':
        return 'Chave aleatória inválida. Deve conter 32 caracteres alfanuméricos.';
      case 'qrCode':
        return 'QR Code inválido.';
      case 'currency':
        return '';
      default:
        return '';
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let formattedValue = value;

    if (type === 'currency') {
      formattedValue = value.replace(/[^0-9,]/g, ''); // Permite apenas números e vírgulas
    }

    if (maskMap[type]) {
      formattedValue = maskMap[type]!(formattedValue);
    }

    if (onChange) {
      onChange({ ...e, target: { ...e.target, value: formattedValue } });
    }
  };

  const renderInput = () => {
    return (
      <S.Input
        type={type === 'currency' ? 'text' : type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        autoComplete={autocomplete}
        readOnly={readOnly}
        aria-label={placeholder}
        {...rest}
      />
    );
  };

  return (
    <>
      <S.Container>{renderInput()}</S.Container>
      {error && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </>
  );
};

export default Input;
