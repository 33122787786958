import React, { useState } from 'react';
import Input from '../../Atons/Input';
import Navbar from '../../Molecules/Navbar';
import * as S from './styled';
import { useAuth } from '../../../context/AuthContext';
import Button from '../../Atons/Button';
import { useToast } from '../../../context/ToastContext';
import { useParams } from 'react-router-dom';
import logo from './logo.png';

const Register: React.FC = () => {
  const { loading, createUser } = useAuth();
  const { notifyError } = useToast();
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [indicated, setiIdicated] = useState<string>('embaixadinhapremiada');
  const { indicator } = useParams<{ indicator?: string }>();
  const [document, setDocument] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      createUser(username, email, password, indicated, document, phone);
    } catch (error) {
      notifyError('Captcha verification failed');
      console.error('Error verifying CAPTCHA:', error);
    }
  };

  return (
    <S.Container src={'./images/background-home-web.png'}>
      <Navbar />
      <S.Logo>
        <img src={logo} alt="" />
      </S.Logo>
      <S.Wrapper onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="NOME"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autocomplete="username"
        />
        <Input
          type="email"
          placeholder="EMAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autocomplete="email"
        />
        <Input
          type="cpf"
          placeholder="CPF"
          value={document}
          onChange={(e) => setDocument(e.target.value)}
          autocomplete="cpf"
        />
        <Input
          type="phone"
          placeholder="TELEFONE"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          autocomplete="tel"
        />
        <Input
          type="password"
          placeholder="SENHA"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autocomplete="current-password"
        />
        <Input
          hidden
          type="text"
          placeholder="Indicado"
          value={indicator ? indicator : indicated}
          autocomplete="username"
          onChange={(e) => setiIdicated(e.target.value)}
          readOnly
        />
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleSubmit(e)}
          variant="primary"
          loading={loading ? true : undefined}
        >
          Cadastrar se
        </Button>
      </S.Wrapper>
    </S.Container>
  );
};

export default Register;
