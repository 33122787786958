import { useCallback } from 'react';
import CryptoJS from 'crypto-js';

// Verifique se a chave de criptografia está definida
const ENCRYPTION_KEY = process.env.REACT_APP_CRIPTO;
if (!ENCRYPTION_KEY) {
  throw new Error('REACT_APP_CRIPTO environment variable is not defined');
}

const useEncryption = () => {
  // Função para criptografar dados
  const encryptData = useCallback((data: string): string => {
    return CryptoJS.AES.encrypt(data, ENCRYPTION_KEY).toString();
  }, []);

  // Função para descriptografar dados
  const decryptData = useCallback((data: string): string => {
    const bytes = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  }, []);

  return { encryptData, decryptData };
};

export default useEncryption;
