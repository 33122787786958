/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { MutableRefObject, useState, useRef } from 'react'
import Button from '../../Atons/Button'
import * as S from './style'
import Navbar from '../../Molecules/Navbar'
import { Link } from 'react-router-dom'

interface AccordionData {
  title: string
  content: string
}

const sampleAccordionData: AccordionData[] = [
  {
    title: 'Como funciona ?',
    content:
      'Embaixadinha Premiada é o mais novo jogo divertido e lucrativo da galera! Lembra daquele joguinho da embaixadinha que todo mundo era viciado? Ele voltou e agora dá para ganhar dinheiro com cada partida jogada, mas cuidado com o tempo para você garantir o seu prêmio. É super simples, faça o máximo de embaixadinhas que conseguir e cuidado com o tempo, a cada embaixadinha que você fizer você ganhará dinheiro na hora.'
  },
  {
    title: 'Como posso jogar ?',
    content:
      'Você precisa fazer um depósito inicial na plataforma para começar a jogar e faturar. Lembrando que você indicando amigos, você ganhará dinheiro de verdade na sua conta bancária.'
  },
  {
    title: 'Como posso sacar ?',
    content:
      'O saque é instantâneo. Utilizamos a sua chave PIX como CPF para enviar o pagamento, é na hora e no PIX. 7 dias por semana e 24 horas por dia.'
  },
  {
    title: 'É tipo foguetinho ?',
    content:
      'Não O jogo do passarinho é totalmente diferente, basta apenas estar atento para passar os canos no momento certo. Não existe sua sorte em jogo, basta ter foco e passar os canos corretamente.'
  },
  {
    title: 'Da para ganhar mais ?',
    content:
      'Chame um amigo para jogar e após o depósito será creditado em sua conta R$ para sacar a qualquer momento. O saldo é adicionado diretamente ao seu saldo em dinheiro, com o qual você pode jogar ou sacar.'
  }
]

interface AccordionItemsProps {
  accordionContent: AccordionData[]
  refs: MutableRefObject<HTMLParagraphElement | null>[]
  currentAccordion: number | null
  setCurrentAccordion: (index: number | null) => void
  setBodyHeight: (height: number) => void
  bodyHeight: number
}

const AccordionItems: React.FC<AccordionItemsProps> = ({
  accordionContent,
  refs,
  currentAccordion,
  setCurrentAccordion,
  setBodyHeight,
  bodyHeight
}) => (
  <>
    {accordionContent.map(({ title, content }, i) => (
      <S.AccordionItem key={`accordion-item-${i}`}>
        <S.AccordionTitle
          onClick={() => {
            setCurrentAccordion(currentAccordion === i ? null : i)
            setBodyHeight(refs[i].current?.clientHeight || 0)
          }}
        >
          {title}
        </S.AccordionTitle>
        <S.AccordionBody
          active={currentAccordion === i}
          bodyHeight={bodyHeight}
        >
          <S.AccordionContent ref={refs[i]}>{content}</S.AccordionContent>
        </S.AccordionBody>
      </S.AccordionItem>
    ))}
  </>
)

const Home: React.FC = () => {
  const [currentAccordion, setCurrentAccordion] = useState<number | null>(null)
  const [bodyHeight, setBodyHeight] = useState(0)

  const refs = sampleAccordionData.map(() => useRef<HTMLParagraphElement>(null))

  return (
    <S.Container>
      <Navbar />
      <S.Hero>
        <S.ContainerHeroMobile>
          <S.Image src="./logo.png" />
          <S.Logo>
            <h1>
              Embaixadinha <span>Premiada</span>
            </h1>
            <h2>Jogue e Ganhe Prêmios!</h2>
          </S.Logo>
          <Button
            variant="secondary"
            onClick={() => console.log('clicou aqui')}
          >
            jogue agora
          </Button>
        </S.ContainerHeroMobile>
        <S.ContainerHero>
          <S.Image src="./logo.png" />
          <div>
            <S.Logo>
              <h1>
                Embaixadinha <span>Premiada</span>
              </h1>
              <h2>Jogue e Ganhe Prêmios!</h2>
            </S.Logo>
            <p>
              Você já imaginou como seria transformar suas habilidades de
              embaixadinha em prêmios incríveis? Com o{' '}
              <strong>Embaixadinha Premiada</strong>, isso agora é possível!
              Prepare-se para se divertir e ganhar recompensas enquanto mostra
              seu talento com a bola!
            </p>
            <S.LinkHome to={'/register'}>
              Jogue Agora!
            </S.LinkHome>
          </div>
        </S.ContainerHero>
      </S.Hero>
      <S.Divider>
        <h2>
          JOGUE <span>AGORA</span>
        </h2>
        <p>
          Com o Embaixadinha Premiada, suas habilidades de embaixadinha podem se
          transformar em prêmios surpreendentes! Jogue, divirta-se e ganhe
          recompensas enquanto mostra seu talento com a bola! Não perca tempo!
        </p>
        <S.LinkHome to={'/register'}>
          Clique aqui para começar agora e ganhe seus prêmios!
        </S.LinkHome>
      </S.Divider>
      <S.About>
        <S.Render>
          <img src="./images/render-home.png" />
        </S.Render>
        <S.Image src="./logo.png" />
        <h3>Por que jogar o Embaixadinha Premiada?</h3>
        <ul>
          <li>
            Desafios diários que testam suas habilidades e te mantêm motivado
          </li>
          <li>
            Competições semanais com prêmios exclusivos para os melhores
            jogadores
          </li>
          <li>
            Tabela de Líderes onde você pode acompanhar seu progresso e competir
            com amigos
          </li>
          <li>
            Prêmios incríveis como equipamentos de futebol, troféus e até
            dinheiro!
          </li>
        </ul>
        <S.LinkHome to={'/register'}>cadastre se agora!</S.LinkHome>
      </S.About>
      <S.Divider>
        <h2>Não perca essa chance!</h2>
        <p>
          Clique no botão abaixo e comece a transformar suas habilidades em
          prêmios hoje mesmo:
        </p>
        <S.LinkHome to={'/'}>Jogue Agora e Ganhe Prêmios!</S.LinkHome>
      </S.Divider>
      <S.Cta>
        <img src="./logo.png" />
        <S.AccordionContainer>
          <h2>FAQ!</h2>
          <S.AccordionInner>
            <AccordionItems
              accordionContent={sampleAccordionData}
              refs={refs}
              currentAccordion={currentAccordion}
              setCurrentAccordion={setCurrentAccordion}
              setBodyHeight={setBodyHeight}
              bodyHeight={bodyHeight}
            />
          </S.AccordionInner>
        </S.AccordionContainer>
      </S.Cta>
      <S.Footer>
        <p>
          &copy; 2024 Embaixadinha Premiada. Todos os direitos reservados.{' '}
          <Link to={'/terms'}>Termos de Uso</Link>
        </p>
      </S.Footer>
    </S.Container>
  )
}

export default Home
