/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import * as S from './styled';
import { Container } from '../../Atons/Container';
import Input from '../../Atons/Input';
import Button from '../../Atons/Button';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../context/ToastContext';

const NewPassword = () => {
  const { loading, requestPasswordReset } = useAuth();
  const [email, setEmail] = useState<string>('');
  const { notifyError, notifySuccess } = useToast();

  const handleClick = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await requestPasswordReset(email);
      notifySuccess('Pedido realizado com sucesso!');
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  return (
    <Container fixed={'true'}>
      <S.ImgLogo src={'./logo.png'} alt="" />
      <S.Wrapper>
        <h2>Recuperar Senha</h2>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autocomplete="email"
        />
        <S.LinkResetPassword to={'/login'}>
          Lembrou sua senha ?
        </S.LinkResetPassword>
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}
          variant="primary"
          loading={loading ? true : undefined}
        >
          Recuperar Senha
        </Button>
      </S.Wrapper>
    </Container>
  );
};

export default NewPassword;
