import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useUser } from '../../../context/UserContext';
import { GiSoccerBall } from 'react-icons/gi';
import { FaMoneyBillTransfer, FaMoneyBillTrendUp } from 'react-icons/fa6';
import { PiHandWithdraw } from 'react-icons/pi';
import { FaHistory, FaShareAlt } from 'react-icons/fa';
import { IoMdLogOut } from 'react-icons/io';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';

const Navbar: React.FC = React.memo(() => {
  const { logout } = useAuth();
  const { user, loadingUser } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleDeposit = () => {
    navigate('/deposito');
  };

  return (
    <S.Nav>
      <S.Logo to={user ? '/jogue-agora' : '/'} aria-label="Go to Home">
        <img src="./logo.png" alt="Logo" />
      </S.Logo>
      <div>
        {!loadingUser && user && (
          <S.BtnSaldoMobile
            onClick={handleDeposit}
            aria-label={`Saldo: R$ ${user.balance.toFixed(2)}`}
          >
            <MdAccountBalanceWallet />
            R$ {user.balance.toFixed(2)}
          </S.BtnSaldoMobile>
        )}
        <S.Hamburger onClick={toggleMenu} aria-label="Toggle menu">
          <span />
          <span />
          <span />
        </S.Hamburger>
      </div>
      <S.Menu open={isOpen}>
        {!loadingUser && !user ? (
          <>
            <S.MenuLink to="/login">Login</S.MenuLink>
            <S.MenuLink to="/register" className="active">
              Cadastrar
            </S.MenuLink>
          </>
        ) : (
          <>
            <S.MenuLink to="/deposito" aria-label="Depositar">
              <FaMoneyBillTransfer /> Depositar
            </S.MenuLink>
            <S.MenuLink to="/compartilhar" aria-label="Compartilhar">
              <FaShareAlt />
              Compartilhar
            </S.MenuLink>
            <S.MenuLink to="/saque" aria-label="Sacar">
              <PiHandWithdraw />
              Sacar
            </S.MenuLink>
            <S.MenuLink to="/jogadas" aria-label="Jogadas">
              <FaHistory />
              Jogadas
            </S.MenuLink>
            <S.MenuLink to="/financeiro" aria-label="Financeiro">
              <FaMoneyBillTrendUp />
              Financeiro
            </S.MenuLink>
            <S.MenuLink to="/jogue-agora" aria-label="Jogar">
              <GiSoccerBall /> Jogar
            </S.MenuLink>
            <S.BtnSaldo
              aria-label={`Saldo: R$ ${user?.balance?.toFixed(2) || '0.00'}`}
              onClick={handleDeposit}
            >
              <MdAccountBalanceWallet />
              R$ {user?.balance?.toFixed(2) || '0.00'}
            </S.BtnSaldo>
            <S.BtnLink onClick={logout} aria-label="Sair">
              <IoMdLogOut />
              Sair
            </S.BtnLink>
          </>
        )}
      </S.Menu>
    </S.Nav>
  );
});

Navbar.displayName = 'Navbar';

export default Navbar;
