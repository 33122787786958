import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    const csrfToken = localStorage.getItem('csrfToken');
    if (csrfToken) {
      config.headers['X-CSRF-Token'] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async (email: string, password: string) => {
  const response = await api.post(`${API_URL}/login`, { email, password });
  return response.data;
};

export const createUser = async (
  username: string,
  email: string,
  password: string,
  indicated: string,
  document: string,
  phone: string
) => {
  const response = await api.post(`${API_URL}/register`, {
    username,
    email,
    password,
    indicated,
    document,
    phone
  });
  return response.data;
};

export const requestPasswordReset = async (email: string) => {
  const response = await api.post(`${API_URL}/request-password-reset`, {
    email
  });
  return response.data;
};

export const resetPassword = async (token: string, newPassword: string) => {
  const response = await api.post(`${API_URL}/reset-password/${token}`, {
    newPassword
  });
  return response.data;
};

export const confirmEmail = async (token: string) => {
  const response = await api.get(`${API_URL}/confirm-email/${token}`);
  return response.data;
};

export const fetchCaptcha = async () => {
  try {
    const response = await api.get('/captcha', { responseType: 'json' }); // Ajuste responseType conforme necessário
    return response.data;
  } catch (error) {
    console.error('Error fetching CAPTCHA:', error);
    throw error;
  }
};

export const setCsrfToken = (csrfToken: string | null) => {
  if (csrfToken) {
    localStorage.setItem('csrfToken', csrfToken);
  }
};
