/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { useNavigate } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';

const useNavigateWithLoading = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();

  const navigateWithLoading = (
    to: string,
    options?: { replace?: boolean; state?: any }
  ) => {
    setLoading(true);
    navigate(to, options);
    setTimeout(() => {
      setLoading(false);
    }, 100); // Delay of 100ms
  };
 
  return navigateWithLoading;
};

export default useNavigateWithLoading;
