import styled, { css, keyframes } from 'styled-components';

// Animação de carregamento
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Contêiner do loading
export const LoadingContainer = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Animação de loading
export const LoadingSpinner = styled.div`
  border: 8px solid ${(props) => props.theme.colors.primary};
  border-top: 8px solid ${(props) => props.theme.colors.white};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 96%;
  margin: 0 auto;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const Input = styled.input`
  background: 0;
  border: 0;
  outline: none;
  font-size: 1em;
  transition: padding 0.3s 0.2s ease;
  width: 100%;
  height: 48px;
  padding-left: 0.5rem;
  margin: 0.5rem 0;
  background-color: #f1f1f1;
  border-radius: 0.5rem;

  &:focus {
    padding-bottom: 0px;
  }

  &:focus + .line {
    &:after {
      transform: scaleX(1);
    }
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;

interface WarningProps {
  type: string;
}

export const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 96%;
  margin: 0 1rem;
  border-radius: 8px;
  padding: 1rem;
  background: ${(props) => props.theme.colors.primary};
`;

export const Warning = styled.div<WarningProps>`
  display: flex;
  align-items: center;
  font-weight: 800;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem 0;

  ${(props) =>
    props.type === 'bônus' &&
    css`
      display: block;
      background: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.black};
    `}

  ${(props) =>
    props.type === 'warning' &&
    css`
      flex-direction: column;
      justify-content: center;

      background: transparent;
      color: ${(props) => props.theme.colors.white};
    `}

    ${(props) =>
    props.type === 'button' &&
    css`
      flex-direction: row;

      background: transparent;
      color: ${(props) => props.theme.colors.secondary};

      button {
        padding: 1rem;
        max-width: 300px;
        margin: 4px;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 1rem;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props) => props.theme.colors.secondary};
        color: rgb(0, 0, 0);
        transition: 0.3s;

        &:hover {
          background-color: ${(props) => props.theme.colors.primary};
        }
      }
    `}
`;

export const ConfirmPayment = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
