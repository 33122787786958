import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.form`
  width: 92%;
  max-width: 420px;
  min-height: 244.59px;
  padding: 0.5rem;

  background-color: #00000090;
  box-shadow: ${(props) =>
    css`
      ${props.theme.colors.shadow} 0px 2px 8px
    `};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.primary};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  h2 {
    width: 100%;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 800;
  }
`;
export const ImgLogo = styled.img`
  width: 44%;
  max-width: 300px;
  margin-bottom: 1rem;
`;

export const LinkResetPassword = styled(Link)`
  width: 100%;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const FooterLogin = styled.div`
  width: 100%;
  height: 32px;
  background: #f1f1f1;
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.8rem;
  color: #999;
`;
