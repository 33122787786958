/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback } from 'react';
import Input from '../../Atons/Input';
import Select from '../../Atons/Select';
import Button from '../../Atons/Button';
import { useOrders } from '../../../hooks/useOrders';
import { useUser } from '../../../context/UserContext';
import { useToast } from '../../../context/ToastContext';
import * as S from './styled';

enum PixType {
  CPF = 'CPF/CNPJ',
  PHONENUMBER = 'Telefone',
  EMAIL = 'Email',
  RANDOMKEY = 'Chave Aleatória'
}

const PixTypeMapping: Record<PixType, string> = {
  [PixType.CPF]: 'document',
  [PixType.PHONENUMBER]: 'phoneNumber',
  [PixType.EMAIL]: 'email',
  [PixType.RANDOMKEY]: 'randomKey'
};

interface FormProps {
  initialForm: PixType;
  formTypes: Record<string, string>;
  title: string;
}

const FormComponent: React.FC<FormProps> = ({
  initialForm,
  formTypes,
  title
}) => {
  const { createTransferOrder } = useOrders();
  const [selectedForm, setSelectedForm] = useState<string>(initialForm);
  const [name, setName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [randomKey, setRandomKey] = useState<string>('');
  const [value, setValue] = useState<string>('50,00');
  const [loading, setLoading] = useState<boolean>(false);
  const { getUserId, user } = useUser();
  const { notifyError, notifySuccess } = useToast();

  const handleFormChange = useCallback((value: string | string[]) => {
    if (Array.isArray(value)) {
      setSelectedForm(value[0] || '');
    } else {
      setSelectedForm(value);
    }
  }, []);

  const formatCurrency = (value: string): string => {
    const cleanValue = value.replace(/\D/g, '');

    const formattedValue = (Number(cleanValue) / 100)
      .toFixed(2)
      .replace('.', ',');

    return formattedValue;
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatCurrency(inputValue);
    setValue(formattedValue);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const id = getUserId();
    if (id) {
      try {
        const apiTypeKey = PixTypeMapping[selectedForm as PixType];

        const numericValue = parseFloat(
          value.replace(/\./g, '').replace(',', '.')
        );

        const transferData = {
          userId: id,
          amount: numericValue,
          pixKey:
            selectedForm === PixType.CPF
              ? cpf
              : selectedForm === PixType.EMAIL
              ? email
              : selectedForm === PixType.PHONENUMBER
              ? phoneNumber
              : randomKey,
          typeKey: apiTypeKey
        };

        await createTransferOrder(transferData);
        notifySuccess('Saque realizado com sucesso!');
      } catch (error: any) {
        console.error('Erro ao realizar o saque:', error);
        notifyError(error.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderForm = () => {
    switch (selectedForm) {
      case PixType.CPF:
        return (
          <S.WrapperContent>
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="Nome do destinatário"
              aria-label="Digite o nome do destinatário"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              id="cpf"
              type="cpf"
              name="cpf"
              placeholder="Digite seu cpf"
              aria-label="Cpf"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
            <Input
              id="value"
              type="currency"
              name="value"
              placeholder="Valor do saque"
              aria-label="Digite o valor do saque"
              value={value}
              onChange={handleValueChange}
            />
            <S.Label type="error">
              <span>Taxa de saque R$ 5,00</span>
              <span>Saque mínimo R$ {user?.withdrawalMin}</span>
            </S.Label>
            <Button
              onClick={handleSubmit}
              variant="primary"
              loading={loading ? 'true' : undefined}
            >
              Sacar
            </Button>
          </S.WrapperContent>
        );
      case PixType.EMAIL:
        return (
          <S.WrapperContent>
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="Nome do destinatário"
              aria-label="Digite o nome do destinatário"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="Digite seu Email"
              aria-label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              id="value"
              type="currency"
              name="value"
              placeholder="Valor do saque"
              aria-label="Digite o valor do saque"
              value={value}
              onChange={handleValueChange}
            />
            <S.Label type="error">
              <span>Taxa de saque R$ 5,00</span>
              <span>Saque mínimo R$ {user?.withdrawalMin}</span>
            </S.Label>
            <Button
              onClick={handleSubmit}
              variant="primary"
              loading={loading ? 'true' : undefined}
            >
              Sacar
            </Button>
          </S.WrapperContent>
        );
      case PixType.PHONENUMBER:
        return (
          <S.WrapperContent>
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="Nome do destinatário"
              aria-label="Digite o nome do destinatário"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              id="phone"
              type="phone"
              name="phone"
              placeholder="Digite seu numero"
              aria-label="phone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Input
              id="value"
              type="currency"
              name="value"
              placeholder="Valor do saque"
              aria-label="Digite o valor do saque"
              value={value}
              onChange={handleValueChange}
            />
            <S.Label type="error">
              <span>Taxa de saque R$ 5,00</span>
              <span>Saque mínimo R$ {user?.withdrawalMin}</span>
            </S.Label>
            <Button
              onClick={handleSubmit}
              variant="primary"
              loading={loading ? 'true' : undefined}
            >
              Sacar
            </Button>
          </S.WrapperContent>
        );
      case PixType.RANDOMKEY:
        return (
          <S.WrapperContent>
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="Nome do destinatário"
              aria-label="Digite o nome do destinatário"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              id="randomKey"
              type="randomKey"
              name="randomKey"
              placeholder="Digite sua chave"
              aria-label="randomKey"
              value={randomKey}
              onChange={(e) => setRandomKey(e.target.value)}
            />
            <Input
              id="value"
              type="currency"
              name="value"
              placeholder="Valor do saque"
              aria-label="Digite o valor do saque"
              value={value}
              onChange={handleValueChange}
            />
            <S.Label type="error">
              <span>Taxa de saque R$ 5,00</span>
              <span>Saque mínimo R$ {user?.withdrawalMin}</span>
            </S.Label>
            <Button
              onClick={handleSubmit}
              variant="primary"
              loading={loading ? 'true' : undefined}
            >
              Sacar
            </Button>
          </S.WrapperContent>
        );
      default:
        return (
          <div>
            <S.Label htmlFor="name">Escolha sua chave pix!</S.Label>
          </div>
        );
    }
  };

  const selectOptions = Object.entries(formTypes).map(([key, value]) => ({
    value,
    label: value
  }));

  return (
    <S.FormWrapper>
      <S.SelectWrapper>
        <S.Title>{title}</S.Title>
        <S.Label type="primary" htmlFor="formSelect">
          Escolha um tipo de chave pix
        </S.Label>
        <Select
          options={selectOptions}
          ariaLabel="Escolha um tipo de chave pix"
          value={selectedForm}
          onChange={handleFormChange}
        />
        {renderForm()}
      </S.SelectWrapper>
    </S.FormWrapper>
  );
};

export default FormComponent;
