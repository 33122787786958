export const themeLight = {
  colors: {
    primary: '#4ff98c',
    secondary: '#ffd500',
    white: '#fff',
    black: '#000',
    shadow: 'rgba(0, 0, 0, 0.15)'
  },
  borderRadius: '0.5rem',
  fonts: ['Cruyff Sans'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em'
  },
  sizes: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1200px'
  }
};

export const themeDark = {
  colors: {
    primary: '#4ff98c',
    secondary: '#ffd500',
    white: '#fff',
    black: '#000',
    shadow: 'rgba(0, 0, 0, 0.15)'
  },
  borderRadius: '0.5rem',
  fonts: ['Cruyff Sans'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em'
  },
  sizes: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1200px'
  }
};
