import { useApi } from '../api/api'; // Corrected import
import { useOrderContext } from '../context/OrderContext';

interface Client {
  name: string;
  document: string;
  phoneNumber: string;
  email: string;
}

interface PixPaymentDetails {
  id: number;
  requestNumber: string;
  dueDate: string;
  amount: number;
  shippingAmount: number;
  discountAmount: number;
  usernameCheckout: string;
  callbackUrl: string;
  client: Client;
}

interface PixPaymentResponse {
  paymentCodeBase64: string;
  paymentCode: string;
}

export const usePixPayment = () => {
  const { state, setPaymentStatus } = useOrderContext();
  const { apiAuth } = useApi(); // Use the useApi hook

  const createPixPayment = async (
    paymentDetails: PixPaymentDetails
  ): Promise<PixPaymentResponse> => {
    try {
      const response = await apiAuth.post('/order/pix', paymentDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to create Pix payment');
      }

      const { paymentCodeBase64, paymentCode } = response.data;
      setPaymentStatus(response.data);

      return { paymentCodeBase64, paymentCode };
    } catch (error) {
      console.error('Error creating Pix payment:', error);
      throw error;
    }
  };

  return {
    paymentStatus: state.paymentStatus,
    createPixPayment
  };
};
