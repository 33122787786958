import React from 'react';
import { Container } from '../../Atons/Container';
import Navbar from '../../Molecules/Navbar';

import FormSelect from '../../Molecules/FormSelect';

import * as S from './styled';

enum PixType {
  CPF = 'CPF/CNPJ',
  PHONENUMBER = 'Telefone',
  EMAIL = 'Email',
  RANDOMKEY = 'Chave Aleatória'
}

const Withdraw = () => {
  return (
    <Container>
      <Navbar />
      <S.Wrapper>
        <S.Content>
          <FormSelect
            title="Saque"
            initialForm={PixType.CPF}
            formTypes={PixType}
          />
        </S.Content>
      </S.Wrapper>
    </Container>
  );
};

export default Withdraw;
