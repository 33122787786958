import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  .line {
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -2px;
    background: #bdc3c7;
    &:after {
      content: ' ';
      position: absolute;
      float: right;
      width: 100%;
      height: 3px;

      transform: scalex(0);
      transition: transform 0.3s ease;

      background: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const Input = styled.input`
  background: 0;
  border: 0;
  outline: none;
  font-size: 1em;
  transition: padding 0.3s 0.2s ease;
  width: 100%;
  height: 48px;
  padding-left: 0.5rem;
  margin: 0.5rem 0;
  background-color: #f1f1f1;
  border-radius: 0.5rem;

  &:focus {
    padding-bottom: 0px;
  }

  &:focus + .line {
    &:after {
      transform: scaleX(1);
    }
  }
`;

export const ErrorMessage = styled.span`
  width: 100%;
  text-align: left;
  color: red;
  font-size: 0.8em;
  transition: 0.3s ease;
  font-weight: 600;
`;
