/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { StyledButtonProps } from './types';

export const Button = styled.button<StyledButtonProps>`
  padding: 1rem;
  width: 100%;
  max-width: 300px;
  margin: 10px;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius};
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1rem;

  transition: 0.3s ease;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.black};
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background-color: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.black};
      &:hover {
        background-color: ${(props) => props.theme.colors.primary};
      }
    `}

    ${({ variant }) =>
    variant === 'red' &&
    css`
      background-color: #d31603;
      color: ${(props) => props.theme.colors.white};
      &:hover {
        background-color: #e2252b;
      }
    `}

  ${({ disabled, loading }) =>
    (disabled || loading) &&
    css`
      background-color: gray;
      cursor: not-allowed;
    `}
`;
export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid white;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
