/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import * as S from './styled';
import Input from '../../Atons/Input';
import Button from '../../Atons/Button';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../context/ToastContext';
import { useParams } from 'react-router-dom';
import logo from './logo.png';
import background from './background-home-web.png';

const NewPassword = () => {
  const { loading, resetPassword } = useAuth();
  const { token } = useParams<{ token?: string }>();
  const [email, setEmail] = useState<string>('');
  const { notifyError, notifySuccess } = useToast();

  const handleClick = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await resetPassword(token, email);
      notifySuccess('Senha atualizada com sucesso');
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  return (
    <S.Container src={background}>
      <S.ImgLogo src={logo} alt="" />
      <S.Wrapper>
        <h2>Nova senha</h2>
        <Input
          type="password"
          placeholder="Digite sua nova senha"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autocomplete="current-password"
        />
        <Button
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e)}
          variant="primary"
          loading={loading ? true : undefined}
        >
          Salvar Senha
        </Button>
      </S.Wrapper>
    </S.Container>
  );
};

export default NewPassword;
