import React from 'react';
import { OrderProvider } from '../../../context/OrderContext';
import { Container } from '../../Atons/Container';
import Navbar from '../../Molecules/Navbar';
import OrderTable from '../../Molecules/OrderTable';

import * as S from './styled';

const OrderPage = () => {
  return (
    <OrderProvider>
      <Container>
        <Navbar />
        <S.Wrapper>
          <OrderTable />
        </S.Wrapper>
      </Container>
    </OrderProvider>
  );
};

export default OrderPage;
