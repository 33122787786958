import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    max-width: 100%;
  }
  th,
  td {
    padding: 12px 15px;
    text-align: left;
  }
  th {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.black};
    text-transform: uppercase;
    font-weight: 800;
  }
  tr {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    line-height: 1.2rem;
    svg {
      font-size: 1.2rem;
    }
  }
  tr:nth-of-type(even) {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
    border-bottom: 1px solid transparent;
  }
  tr:last-of-type {
    border-bottom: 1px solid transparent;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const PageButton = styled.button<{ isActive: boolean }>`
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.primary : props.theme.colors.white};
  color: ${(props) =>
    props.isActive ? props.theme.colors.black : props.theme.colors.black};
  border: 1px solid ${(props) => props.theme.colors.primary};
  margin: 0 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
  }
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderItem = styled.div`
  width: 24%;
  height: 64px;
  background: ${(props) => props.theme.colors.white};
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderItemTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  margin-bottom: 0.5rem;
  font-size: 0rem;
  svg {
    font-size: 1rem;
  }

  @media only screen and (min-width: 440px) {
    svg {
      font-size: 1.4rem;
    }
  }

  @media only screen and (min-width: 764px) {
    font-size: 1rem;
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const FooterTable = styled.div`
  width: 100%;
  height: 64px;
  padding: 0.5rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.black};
  border-radius: 0.5rem;
`;

export const Warning = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 800;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem 0;
  background: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.black};
`;
