import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;

  padding: 1rem;

  background: #00000080;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 800px;
`;

export const Title = styled.div`
  width: 100%;

  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: 800;

  margin: 1rem 0;
`;

export const WrapperInput = styled.div`
  width: 100%;
  padding: 0.5rem;
  background: ${(props) => props.theme.colors.secondary};
  border-radius: 0.5rem;

  span {
    color: #000;
    margin: 0.5rem 0;
    font-weight: 800;
  }
`;
