import React from 'react';
import { StyledButtonProps } from './types';

import * as S from './styled';

const Button: React.FC<StyledButtonProps> = ({
  onClick,
  disabled,
  loading,
  variant = 'primary',
  children
}) => {
  return (
    <S.Button
      onClick={onClick}
      disabled={disabled || loading}
      variant={variant}
      loading={loading ? 'true' : undefined}
    >
      {loading ? <S.Spinner /> : children}
    </S.Button>
  );
};

export default Button;
