import React, { useEffect, useState } from 'react';
import * as S from './styled';
import Navbar from '../../Molecules/Navbar';
import { useUser } from '../../../context/UserContext';
import { useMatch } from '../../../context/MatchContext'; // Importa o MatchContext
import { Link } from 'react-router-dom';
import Input from '../../Atons/Input';
import { useToast } from '../../../context/ToastContext';
import { useNavigate } from 'react-router-dom';

// Componente de loading estilizado para ser exibido enquanto os dados do usuário são carregados
const LoadingIndicator = () => (
  <div>
    <div>Carregando...</div>
  </div>
);

const Painel: React.FC = () => {
  const { user, loadingUser, error, loadUser, getToken, getUserId } = useUser();
  const { createMatch } = useMatch();
  const [formattedBalance, setFormattedBalance] = useState<string>('0.00');
  const [formattedPlays, setFormattedPlays] = useState<string>('0.00');
  const [showContent, setShowContent] = useState<boolean>(false);
  const [value, setValue] = useState<string>('10,00');
  const [isDouble, setIsDouble] = useState<boolean>(false);
  const [matchType, setMatchType] = useState<string>('balance');
  const { notifyError } = useToast();
  const navigate = useNavigate();

  const formatCurrency = (value: string): string => {
    const cleanValue = value.replace(/\D/g, '');

    const formattedValue = (Number(cleanValue) / 100)
      .toFixed(2)
      .replace('.', ',');

    return formattedValue;
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const formattedValue = formatCurrency(inputValue);
    setValue(formattedValue);
  };

  const handlePlayClick = async () => {
    if (!user) {
      notifyError('Usuário não autenticado.');
      return;
    }

    const betAmount = parseFloat(value.replace(',', '.'));

    // Verificação se o valor da aposta é válido
    if (isNaN(betAmount) || betAmount <= 0) {
      notifyError('Valor de aposta inválido.');
      return;
    }

    if (betAmount < 1) {
      notifyError('O valor mínimo de aposta é R$ 1,00.');
      return;
    }

    // Verificação para partidas valendo o dobro
    const totalBetAmount = isDouble ? betAmount * 2 : betAmount;

    if (matchType === 'balance') {
      if (totalBetAmount > user.balance) {
        notifyError('Saldo insuficiente.');
        return;
      }
    } else if (matchType === 'play') {
      if (totalBetAmount > user.plays) {
        notifyError('Bônus insuficiente.');
        return;
      }
    } else {
      notifyError('Tipo de partida inválido.');
      return;
    }

    const matchData = {
      userId: user.userId,
      bet: totalBetAmount,
      profit: 0,
      score: 0,
      status: false,
      type: matchType
    };

    try {
      const newMatch = await createMatch(matchData);
      if (newMatch) {
        navigate('/jogar');
      } else {
        notifyError('Erro ao criar a partida.');
      }
    } catch (error) {
      notifyError('Ocorreu um erro ao tentar criar a partida.');
    }
  };

  useEffect(() => {
    const token = getToken();
    const id = getUserId();
    if (id && token) {
      loadUser(id, token);
    }
  }, []);

  useEffect(() => {
    if (user && user.balance !== undefined) {
      setFormattedBalance(user.balance.toFixed(2));
      setFormattedPlays(user.plays.toFixed(2));
    }
  }, [user, value]);

  useEffect(() => {
    if (!loadingUser && !error && user) {
      setShowContent(true);
    }
  }, [loadingUser, error, user]);

  if (loadingUser) {
    return (
      <S.Container>
        <Navbar />
        <S.Wrapper>
          <S.ImgLogo src={'./logo.png'} alt="" />
          <h2>
            embaixadinha <span>premiada</span>
          </h2>
          <LoadingIndicator />
        </S.Wrapper>
        <S.Footer>
          <p>
            &copy; 2024 Embaixadinha Premiada. Todos os direitos reservados.
            <Link to={'/terms'}>Termos de Uso</Link>
          </p>
        </S.Footer>
      </S.Container>
    );
  }

  if (error) {
    return (
      <S.Container>
        <Navbar />
        <S.Wrapper>
          <S.ImgLogo src={'./logo.png'} alt="" />
          <h2>
            embaixadinha <span>premiada</span>
          </h2>
          <div>Erro ao carregar dados do usuário</div>
        </S.Wrapper>
        <S.Footer>
          <p>
            &copy; 2024 Embaixadinha Premiada. Todos os direitos reservados.
            <Link to={'/terms'}>Termos de Uso</Link>
          </p>
        </S.Footer>
      </S.Container>
    );
  }

  if (!showContent && !user) {
    // Caso o conteúdo ainda não esteja pronto após o carregamento
    return (
      <S.Container>
        <Navbar />
        <S.Wrapper>
          <S.ImgLogo src={'./logo.png'} alt="" />
          <h2>
            embaixadinha <span>premiada</span>
          </h2>
          <LoadingIndicator />
        </S.Wrapper>
        <S.Footer>
          <p>
            &copy; 2024 Embaixadinha Premiada. Todos os direitos reservados.
            <Link to={'/terms'}>Termos de Uso</Link>
          </p>
        </S.Footer>
      </S.Container>
    );
  }

  return (
    <S.Container>
      <Navbar />
      <S.Wrapper>
        <S.ImgLogo src={'./logo.png'} alt="" />
        <h2>
          embaixadinha <span>premiada</span>
        </h2>
        <S.Title>Seja bem vindo(a)</S.Title>
        <S.Balance>
          Você possui o saldo de <span>R$ {formattedBalance}</span>
        </S.Balance>
        <S.Plays>
          Você possui <span>R$ {formattedPlays ?? 0}</span> em bônus
        </S.Plays>
        <S.Form>
          <Input
            id="value"
            type="currency"
            name="value"
            placeholder="Valor da aposta"
            aria-label="Digite o valor doa aposta"
            value={value}
            onChange={handleValueChange}
          />
          <S.FormContext>
            <S.Label>
              <S.Check
                variant="xxl"
                type="checkbox"
                checked={isDouble}
                onChange={() => setIsDouble(!isDouble)}
              />
              Dobrar valor
            </S.Label>

            <S.OptionContext>
              <S.Label>
                <S.Radio
                  variant="xxl"
                  type="radio"
                  value="balance"
                  checked={matchType === 'balance'}
                  onChange={() => setMatchType('balance')}
                />
                Saldo
              </S.Label>

              <S.Label>
                <S.Radio
                  variant="xxl"
                  type="radio"
                  value="play"
                  checked={matchType === 'play'}
                  onChange={() => setMatchType('play')}
                />
                Bônus
              </S.Label>
            </S.OptionContext>
          </S.FormContext>
        </S.Form>
        <S.BetLink onClick={handlePlayClick}>Jogar</S.BetLink>
        <S.Plays>
          Teste <span>grátis</span>
        </S.Plays>
        <S.TestLink to="/testar">Testar</S.TestLink>
      </S.Wrapper>
      <S.Footer>
        <p>
          &copy; 2024 Embaixadinha Premiada. Todos os direitos reservados.
          <Link to={'/terms'}>Termos de Uso</Link>
        </p>
      </S.Footer>
    </S.Container>
  );
};

export default React.memo(Painel);
