import React from 'react';
import CreateOrderForm from '../../Organisms/CreateOrderForm';
import { OrderProvider } from '../../../context/OrderContext';
import { Container } from '../../Atons/Container';
import Navbar from '../../Molecules/Navbar';

import * as S from './styled';

const Deposit = () => {
  return (
    <OrderProvider>
      <Container>
        <Navbar />
        <S.Wrapper>
          <CreateOrderForm />
        </S.Wrapper>
      </Container>
    </OrderProvider>
  );
};

export default Deposit;
