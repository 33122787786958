/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../api/api'; // Import useApi hook
import { useNavigate } from 'react-router-dom';
import logo from './logo.png';

import * as S from './styled';

const ConfirmEmail = () => {
  const { token } = useParams<{ token?: string }>();
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const { api } = useApi();

  useEffect(() => {
    navigate('/login');
  }, []);

  return (
    <S.Container src={'./images/background-home-web.png'}>
      <S.Logo>
        <S.ImgLogo src={logo} alt="" />
      </S.Logo>
      <S.Wrapper></S.Wrapper>
    </S.Container>
  );
};

export default ConfirmEmail;
