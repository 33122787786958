import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode
} from 'react';

type AudioContextType = {
  isMuted: boolean;
  audioFormat: string;
  toggleMute: () => void;
  setAudioFormat: (format: string) => void;
};

const AudioContext = createContext<AudioContextType | undefined>(undefined);

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error('useAudio must be used within an AudioProvider');
  }
  return context;
};

export const AudioProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [audioFormat, setAudioFormat] = useState<string>('mp3');

  // Detecta o formato de áudio suportado pelo dispositivo/navegador
  useEffect(() => {
    const audioElement = document.createElement('audio');
    if (
      audioElement.canPlayType('audio/ogg; codecs="vorbis"').replace(/^no$/, '')
    ) {
      setAudioFormat('ogg');
    } else if (audioElement.canPlayType('audio/mp3').replace(/^no$/, '')) {
      setAudioFormat('mp3');
    } else {
      // Define um formato padrão caso nenhum dos dois seja suportado (fallback)
      setAudioFormat('mp3');
    }
  }, []);

  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  return (
    <AudioContext.Provider
      value={{ isMuted, audioFormat, toggleMute, setAudioFormat }}
    >
      {children}
    </AudioContext.Provider>
  );
};
