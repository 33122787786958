/* eslint-disable @typescript-eslint/no-explicit-any */
import { useApi } from '../api/api';
import { useOrderContext } from '../context/OrderContext';
import { Order } from '../context/types/Orders';
import { useUser } from '../context/UserContext';

interface CreateTransferOrderData {
  userId: string;
  amount: number;
  pixKey: string;
  typeKey: string;
}

export const useOrders = () => {
  const { state, dispatch, setLoading } = useOrderContext();
  const { apiAuth } = useApi();
  const { loadUser, getToken, getUserId } = useUser();

  const fetchOrders = async (filters: any = {}, page = 1, limit = 10) => {
    setLoading(true);
    const id = getUserId();
    if (id) {
      try {
        const response = await apiAuth.get(`/order/user/${id}`, {
          params: { ...filters, page, limit }
        });
        if (response.status === 200) {
          const { orders, totalOrders } = response.data;
          dispatch({ type: 'SET_ORDERS', payload: { orders, totalOrders } });
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const createOrder = async (
    orderData: Omit<Order, 'id' | 'createdAt' | 'updatedAt'>
  ): Promise<Order> => {
    setLoading(true);
    const id = getUserId();
    const token = getToken();

    try {
      const response = await apiAuth.post('/order', orderData);
      if (response.status === 200) {
        const newOrder: Order = response.data.order;
        dispatch({ type: 'ADD_ORDER', payload: newOrder });
        if (id && token) {
          loadUser(id, token);
        }
        return newOrder;
      } else {
        console.error(
          'Unexpected server response:',
          response.status,
          response.data
        );
        throw new Error('Failed to create order');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createTransferOrder = async (
    transferData: CreateTransferOrderData
  ): Promise<Order> => {
    setLoading(true);
    const id = getUserId();
    const token = getToken();
    try {
      const response = await apiAuth.post('/order/withdraw', transferData);
      if (response.status === 200) {
        const newOrder: Order = response.data;
        dispatch({ type: 'ADD_ORDER', payload: newOrder });
        if (id && token) {
          loadUser(id, token);
        }
        return newOrder;
      } else {
        console.error(
          'Unexpected server response:',
          response.status,
          response.data
        );
        throw new Error('Failed to create transfer order');
      }
    } catch (error) {
      console.error('Error creating transfer order:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchOrders,
    createOrder,
    createTransferOrder,
    orders: state.orders,
    loading: state.loading,
    totalOrders: state.totalOrders
  };
};
