/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useCallback, RefObject } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode[];
}

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  position: relative;
  z-index: 9999;
`;

const ModalWrapper = styled.div<{ showModal: boolean }>`
  width: 100vw;
  height: 100vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  background-size: cover;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9999;
`;

const ModalContent = styled.div`
  max-width: 96%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: url('./images/background-modal.jpg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 2;
  color: #141414;
  border-radius: 10px;
  position: relative;
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 10px;
  width: 32px;
  height: 32px;
  z-index: 9999;
  color: ${(props) => props.theme.colors.primary};
`;

export const ModalGame: React.FC<ModalProps> = ({
  showModal,
  setShowModal,
  children
}) => {
  const modalRef: RefObject<HTMLDivElement> = useRef(null);

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  });

  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (modalRef.current === e.target) {
      //setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal && (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalContent>
                <CloseModalButton
                  aria-label="Close modal"
                  onClick={() => setShowModal((prev) => !prev)}
                />
                {children}
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      )}
    </>
  );
};
