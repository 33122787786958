// styled.ts
import styled, { keyframes } from 'styled-components';

export const afterRecord = keyframes`
  0% {
    transform: translate(-50%, 10%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 10%) scale(5);
    opacity: 0;
    color: white;
  }
`;

export const Loader = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 35vh;
  font-size: 2rem;
  top: 0;
  left: 0;
  z-index: 999;
  background: #00000080;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  transition: 500ms;
`;

export const GameDiv = styled.div`
  opacity: 0;
  width: 100%;
  max-height: 92vh;
  min-height: 92vh;
  display: block;
  background: #00000050;
  position: relative;
  overflow: hidden;
`;

export const ContainerUI = styled.div`
  position: absolute;
  top: 80px;
  right: 1rem;
`;

export const ChancesBoard = styled.div`
  min-width: 90px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: #00000050;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  margin-top: 0.5rem;
`;

export const Ball = styled.div`
  position: absolute;
  width: 90px;
  height: 90px;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  cursor: pointer;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 10;
  overflow: hidden;
  outline: none;
  user-select: none;
`;

export const InnerBall = styled.div`
  background-image: url('./game/football.png');
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  user-select: none;

  &:focus:not(:focus-visible),
  &:active,
  &:focus,
  &:focus-visible {
    outline: 0;
    box-shadow: none;
    appearance: none;
  }
`;

export const BallShadow = styled.div`
  background: rgba(102, 102, 102, 0.562);
  position: absolute;
  transform: translate(-50%, 50%);
  border-radius: 100%;
  height: 10px;
  bottom: 0;
  overflow: hidden;
  outline: none;
  user-select: none;
`;

export const ScoreBoard = styled.div`
  width: 90px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  background: #00000050;
  padding: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  overflow: hidden;
  outline: none;
  user-select: none;
`;

export const RecordBoard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  cursor: default;
  transition: 800ms;
  font-size: 200px;
  color: ${(props) => props.theme.colors.white};
  transform: translate(-50%, -50%);
  z-index: 0;
  overflow: hidden;
  outline: none;
  user-select: none;
  &.recordmode {
    color: ${(props) => props.theme.colors.primary};
    text-shadow: 0px 0px 20px ${(props) => props.theme.colors.primary};
  }
`;

export const RecordBoardBehind = styled.div`
  animation: ${afterRecord} 800ms ease-out;
  opacity: 0;
  transform: translate(-50%, -10%);
`;

export const HowTo = styled.span`
  text-align: center;
  display: block;
  color: black;
  font-size: 1.5rem;
`;

export const Title = styled.div`
  width: 100%;

  font-size: 1.4rem;
  font-family: 'Cruyff Sans';
  color: ${(props) => props.theme.colors.primary};
  padding: 0.5rem;
  text-transform: uppercase;
  font-weight: 800;

  margin: 1rem 0;

  background: ${(props) => props.theme.colors.black};
`;

export const Text = styled.div`
  width: 100%;

  font-size: 1.3rem;
  font-family: 'Cruyff Sans';
  color: ${(props) => props.theme.colors.black};
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;

  padding: 0.5rem;

  span {
    padding: 8px;
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.secondary};
    border-radius: 0.5rem;
  }
`;

export const BottomGame = styled.div`
  width: 100vw;
  height: 8vh;
  background: #000;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
`;
