import useEncryption from '../hooks/useEncryption';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const useApi = () => {
  const { decryptData, encryptData } = useEncryption(); // Adicionando encryptData

  const api = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      const csrfToken = localStorage.getItem('csrfToken');
      if (csrfToken) {
        config.headers['X-CSRF-Token'] = csrfToken;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const apiAuth = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  apiAuth.interceptors.request.use(
    (config) => {
      const encryptedToken = localStorage.getItem('token');
      if (encryptedToken) {
        const token = decryptData(encryptedToken); // Decrypt the token before using it
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      const csrfToken = localStorage.getItem('csrfToken');
      if (csrfToken) {
        config.headers['X-CSRF-Token'] = csrfToken;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiAuth.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const { data } = await refreshAccessToken();
          const encryptedToken = encryptData(data.token);
          localStorage.setItem('token', encryptedToken);
          originalRequest.headers['Authorization'] = `Bearer ${data.token}`;
          return apiAuth(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }
      return Promise.reject(error);
    }
  );

  const refreshAccessToken = async () => {
    const response = await apiAuth.post('/auth/refresh-token');
    return response.data;
  };

  const setCsrfToken = (csrfToken: string) => {
    localStorage.setItem('csrfToken', csrfToken);
  };

  return { api, apiAuth, setCsrfToken };
};
