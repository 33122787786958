import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface MenuProps {
  open: boolean;
}

export const Nav = styled.nav`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background: ${(props) => props.theme.colors.black};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  position: fixed;
  top: 0;
  z-index: 999;

  div {
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled(Link)`
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  margin-left: 1rem;
  text-transform: uppercase;

  img {
    width: 42px;
  }
`;

export const Hamburger = styled.div`
  display: none; /* Initially hidden */

  /* Show on mobile devices (width <= 768px) */
  @media (max-width: 930px) {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-right: 1rem;

    span {
      height: 3px;
      width: 25px;
      background: #fff;
      margin-bottom: 4px;
      border-radius: 5px;
    }
  }
`;

export const Menu = styled.div<MenuProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-right: 2rem;

  .active {
    background: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.black};
  }

  @media (max-width: 930px) {
    flex-direction: column;
    position: absolute;
    top: 61px;
    left: 0;
    width: 100%;
    background: ${(props) => props.theme.colors.black};
    overflow: hidden;
    max-height: ${({ open }) => (open ? '400px' : '0')};
    opacity: ${({ open }) => (open ? '1' : '0')};
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding: 1rem 0;

    .active {
      background: transparent;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

interface MenuLinkProps {
  active?: string;
}

export const MenuLink = styled(Link)<MenuLinkProps>`
  width: 92%;
  padding-left: 1rem;
  height: 42px;
  color: ${(props) =>
    props.active ? props.theme.colors.black : props.theme.colors.white};
  text-decoration: none;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
  text-transform: capitalize;
  font-weight: 800;
  line-height: 32px;
  background-color: ${(props) => props.active && props.theme.colors.secondary};

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;

    @media (min-width: 769px) {
      display: inline-block;
    }

    @media (max-width: 930px) {
      display: none;
    }
  }

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.black};
  }

  @media (min-width: 768px) {
    width: auto;
  }
`;

export const BtnLink = styled.button`
  width: auto;
  height: 42px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
  font-size: 0.9rem;
  padding: 5px 1rem;
  border-radius: 5px;
  transition: 0.3s;
  text-transform: capitalize;
  font-weight: 800;
  border: none;
  background-color: red;
  text-align: center;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;

    @media (min-width: 769px) {
      display: inline-block;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.black};
  }

  @media (max-width: 768px) {
    width: 92%;
    padding-left: 1rem;
  }
`;

export const BtnSaldo = styled.button`
  width: auto;
  height: 42px;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-size: 0.9rem;
  padding: 5px 1rem;
  border-radius: 5px;
  transition: 0.3s;
  text-transform: capitalize;
  font-weight: 800;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;

    @media (min-width: 769px) {
      display: inline-block;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    width: 92%;
    padding-left: 1rem;
  }
`;

export const BtnSaldoMobile = styled.button`
  width: auto;
  height: 42px;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-size: 0.9rem;
  padding: 5px 1rem;
  border-radius: 5px;
  transition: 0.3s;
  text-transform: capitalize;
  font-weight: 800;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  text-align: center;
  margin-right: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;

    @media (min-width: 769px) {
      display: none;
    }

    @media (max-width: 768px) {
      display: inline-block;
    }
  }

  @media (min-width: 930px) {
    display: none;
  }
`;
