import React, { useEffect, useCallback } from 'react';
import { HiShieldExclamation, HiShieldCheck } from 'react-icons/hi';
import { useUser } from '../../../context/UserContext';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { FaUserPlus } from 'react-icons/fa';
import * as S from './styled';

interface User {
  username: string;
  depositConfirmed: boolean;
}

interface IndicatedUsersTableProps {
  currentPage: number;
  indicatedUsers: User[];
  totalDeposited: number;
  totalIndicatedUsers: number;
  totalNotDeposited: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const MAX_PAGES_TO_SHOW = 10;

const IndicatedUsersTable: React.FC<IndicatedUsersTableProps> = ({
  currentPage,
  indicatedUsers,
  totalDeposited,
  totalIndicatedUsers,
  totalNotDeposited,
  totalPages,
  onPageChange
}) => {
  const { user, getToken, getUserId, getIndicated } = useUser();

  const fetchIndicatedUsers = useCallback(() => {
    const token = getToken();
    const id = getUserId();

    if (id && token) {
      getIndicated(id, token);
    }
  }, [getToken, getUserId, getIndicated]);

  useEffect(() => {
    fetchIndicatedUsers();
  }, []);

  const renderPageButtons = () => {
    const pages = [];
    const startPage = Math.max(
      1,
      currentPage - Math.floor(MAX_PAGES_TO_SHOW / 2)
    );
    const endPage = Math.min(totalPages, startPage + MAX_PAGES_TO_SHOW - 1);

    if (startPage > 1) {
      pages.push(
        <S.PageButton
          key="first"
          isActive={false}
          onClick={() => onPageChange(1)}
        >
          1
        </S.PageButton>
      );
      if (startPage > 2) {
        pages.push(<span key="dots1">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <S.PageButton
          key={i}
          isActive={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </S.PageButton>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="dots2">...</span>);
      }
      pages.push(
        <S.PageButton
          key="last"
          isActive={false}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </S.PageButton>
      );
    }

    return pages;
  };

  return (
    <>
      <S.Header>
        <S.HeaderItem>
          <S.HeaderItemTitle>
            <FaUserPlus /> Total
          </S.HeaderItemTitle>
          {totalIndicatedUsers}
        </S.HeaderItem>
        <S.HeaderItem>
          <S.HeaderItemTitle>
            <HiShieldCheck /> Confirmados
          </S.HeaderItemTitle>
          {totalDeposited}
        </S.HeaderItem>
        <S.HeaderItem>
          <S.HeaderItemTitle>
            <HiShieldExclamation /> Aguardando
          </S.HeaderItemTitle>
          {totalNotDeposited}
        </S.HeaderItem>
        {user && (
          <S.HeaderItem>
            <S.HeaderItemTitle>
              <MdAccountBalanceWallet /> Saldo
            </S.HeaderItemTitle>
            R${user.balance.toFixed(2)}
          </S.HeaderItem>
        )}
      </S.Header>
      {indicatedUsers.length > 0 ? (
        <S.TableContainer>
          <S.Table>
            <thead>
              <tr>
                <th>Usuário</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {indicatedUsers.map((user, index) => (
                <tr key={index}>
                  <td>{user.username}</td>
                  <td>
                    {user.depositConfirmed ? (
                      <>
                        <HiShieldCheck /> Confirmado
                      </>
                    ) : (
                      <>
                        <HiShieldExclamation /> Aguardando
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </S.Table>
          <S.Pagination>
            {currentPage > 1 && (
              <S.PageButton
                isActive={false}
                onClick={() => onPageChange(currentPage - 1)}
              >
                Anterior
              </S.PageButton>
            )}
            {renderPageButtons()}
            {currentPage < totalPages && (
              <S.PageButton
                isActive={false}
                onClick={() => onPageChange(currentPage + 1)}
              >
                Próximo
              </S.PageButton>
            )}
          </S.Pagination>
        </S.TableContainer>
      ) : (
        <S.Warning>
          Parece que você não tem nenhum usuário convidado!!
        </S.Warning>
      )}
      <S.FooterTable>
        Os valores serão debitados do saldo real da sua conta do jogo.
      </S.FooterTable>
    </>
  );
};

export default IndicatedUsersTable;
