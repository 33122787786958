/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components'
import media from '../../Atons/Breakpoints'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  width: 80%;
  max-width: 300px;
`

export const Logo = styled.div`
  width: 80%;

  h1 {
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    margin: 1rem 0;
    span {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  h2 {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.white};
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    margin: 1rem 0;
  }
`

export const ContainerHero = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    max-width: 980px;
    display: flex;
    flex-direction: column;
    margin: 0 1rem;

    h1,
    h2 {
      text-align: left;
      width: 100%;
      margin: 0;
    }

    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2.5rem;
    }

    p {
      color: ${(props) => props.theme.colors.white};
      font-weight: 800;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin: 1rem;
    }
  }
`

export const ContainerHeroMobile = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Hero = styled.div`
  width: 100vw;
  height: 100vh;

  background-image: url('./images/background-home-mobile.png');
  background-size: cover;
  background-position: center center;

  ${ContainerHero} {
    display: none;
    visibility: hidden;
  }

  ${media.mobile`
    background-image: url('./images/background-home-mobileX2.png');
  `}

  ${media.tablet`
    background-image: url('./images/background-home-tablet.png');
  `}

  ${media.desktop`
    flex-direction: row;
    background-image: url('./images/background-home-web.png');

    ${ContainerHeroMobile}{
      display: none;
      visibility: hidden;
    }

    ${ContainerHero}{
      display: flex;
      visibility: visible;
    }
  `}
`

export const LinkHome = styled(Link)`
  width: 100%;
  max-width: 280px;
  height: 48px;

  background: ${(props) => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0.5rem 0;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: 0.3s;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  ${media.desktop`
    max-width: 290px;
  `}
`

export const Divider = styled.div`
  width: 100vw;
  height: 440px;
  background-image: url('./images/background-divider.png');
  background-position: center center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${(props) => props.theme.colors.primary};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};

  ${media.desktop`
    height: 300px;
  `}

  h2 {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    margin: 1rem 0;
    span {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  p {
    width: 100%;
    max-width: 390px;
    text-align: center;
    margin: 1rem 0;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 1.27rem;

    ${media.tablet`
      max-width: 700px;
    `}

    ${media.desktop`
      max-width: 840px;
    `}
  }
`

export const Render = styled.div`
  width: 25%;

  img {
    width: 100%;
  }
`

export const AccordionContainer = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.white};
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    margin: 1rem 0;
    span {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`

export const AccordionInner = styled.div`
  position: relative;
  width: 96%;
  border: 1px solid black;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.primary};
`

export const AccordionItem = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid black;
  }
`

export const AccordionTitle = styled.h3`
  margin: 0;
  padding: 1rem;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
`

interface StyleAccordionBody {
  active: boolean
  bodyHeight: number
}

export const AccordionBody = styled.div<StyleAccordionBody>`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;

  ${({ active, bodyHeight }) =>
    active &&
    css`
      height: ${bodyHeight}px;
    `}
`

export const AccordionContent = styled.p`
  margin: 0;
  padding: 0 1rem 1rem;
  height: auto;
`

export const About = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-image: url('./images/background-home-about.png');
  background-position: center center;
  padding: 1rem 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  position: relative;

  ${Render} {
    display: none;
    visibility: hidden;
  }

  ${Image} {
    max-width: 250px;
  }

  h3 {
    color: ${(props) => props.theme.colors.secondary};
    font-weight: 800;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
  }
  ul {
    width: 96%;
    max-width: 380px;
    padding: 1rem;

    li {
      margin: 1.4rem 1rem;
      padding: 0.5rem;
      color: ${(props) => props.theme.colors.black};
      background-color: ${(props) => props.theme.colors.primary};
      text-transform: uppercase;
      font-weight: 800;
    }
  }

  ${media.desktop`

    ${Render}{
      display: flex;
      visibility: visible;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    h3{
      font-size:2.1rem;
    }

    ul{
      max-width: 780px;
      li{
        font-size: 1.2rem;
        background: transparent;
        color: ${(props) => props.theme.colors.white};
      }
    }
  `}
`

export const Cta = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-image: url('./images/background-home-cta.png');
  background-position: center center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 250px;
  }
`

export const Footer = styled.footer`
  width: 100%;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.black};

  color: ${(props) => props.theme.colors.white};
  text-align: center;

  a {
    color: ${(props) => props.theme.colors.primary};
  }
`
