/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface ContainerProps {
  fixed?: string;
  isgame?: string;
}
//BG-Web-1080p
export const Container = styled.div<ContainerProps>`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.colors.primary};
  background-image: ${(props) =>
    props.isgame
      ? "url('./images/BG-Web-1080p.jpg')"
      : "url('./images/background-home-web.png')"};
  background-size: cover;
  background-position: top center;
  overflow-x: hidden;

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      touch-action: none;
    `}
`;

export const ContainerGame = styled.div<ContainerProps>`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  color: ${(props) => props.theme.colors.primary};
  background-image: ${(props) =>
    props.isgame
      ? "url('./images/BG-Web-1080p.jpg')"
      : "url('./images/background-home-web.png')"};
  background-size: cover;
  background-position: top center;
  overflow-x: hidden;

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;
      touch-action: none;
    `}
`;
