import { css } from 'styled-components';

export const FontFaces = css`
  @font-face {
    font-family: 'Cruyff Sans';
    src: url('/fonts/CruyffSans-Medium.eot');
    src: local('Cruyff Sans Medium'), local('CruyffSans-Medium'),
      url('/fonts/CruyffSans-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CruyffSans-Medium.woff2') format('woff2'),
      url('/fonts/CruyffSans-Medium.woff') format('woff'),
      url('/fonts/CruyffSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans Mono';
    src: url('/fonts/CruyffSansMono-Bold.eot');
    src: local('Cruyff Sans Mono Bold'), local('CruyffSansMono-Bold'),
      url('/fonts/CruyffSansMono-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CruyffSansMono-Bold.woff2') format('woff2'),
      url('/fonts/CruyffSansMono-Bold.woff') format('woff'),
      url('/fonts/CruyffSansMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans Expanded';
    src: url('/fonts/CruyffSansExpanded-Heavy.eot');
    src: local('Cruyff Sans Expanded Heavy'), local('CruyffSansExpanded-Heavy'),
      url('/fonts/CruyffSansExpanded-Heavy.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/CruyffSansExpanded-Heavy.woff2') format('woff2'),
      url('/fonts/CruyffSansExpanded-Heavy.woff') format('woff'),
      url('/fonts/CruyffSansExpanded-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans Mono';
    src: url('/fonts/CruyffSansMono-Medium.eot');
    src: local('Cruyff Sans Mono Medium'), local('CruyffSansMono-Medium'),
      url('/fonts/CruyffSansMono-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CruyffSansMono-Medium.woff2') format('woff2'),
      url('/fonts/CruyffSansMono-Medium.woff') format('woff'),
      url('/fonts/CruyffSansMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans Condensed';
    src: url('/fonts/CruyffSansCondensed-Regular.eot');
    src: local('Cruyff Sans Condensed Regular'),
      local('CruyffSansCondensed-Regular'),
      url('/fonts/CruyffSansCondensed-Regular.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/CruyffSansCondensed-Regular.woff2') format('woff2'),
      url('/fonts/CruyffSansCondensed-Regular.woff') format('woff'),
      url('/fonts/CruyffSansCondensed-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans Condensed';
    src: url('/fonts/CruyffSansCondensed-Medium.eot');
    src: local('Cruyff Sans Condensed Medium'),
      local('CruyffSansCondensed-Medium'),
      url('/fonts/CruyffSansCondensed-Medium.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/CruyffSansCondensed-Medium.woff2') format('woff2'),
      url('/fonts/CruyffSansCondensed-Medium.woff') format('woff'),
      url('/fonts/CruyffSansCondensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans';
    src: url('/fonts/CruyffSans-Regular.eot');
    src: local('Cruyff Sans Regular'), local('CruyffSans-Regular'),
      url('/fonts/CruyffSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CruyffSans-Regular.woff2') format('woff2'),
      url('/fonts/CruyffSans-Regular.woff') format('woff'),
      url('/fonts/CruyffSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans Mono';
    src: url('/fonts/CruyffSansMono-Regular.eot');
    src: local('Cruyff Sans Mono Regular'), local('CruyffSansMono-Regular'),
      url('/fonts/CruyffSansMono-Regular.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/CruyffSansMono-Regular.woff2') format('woff2'),
      url('/fonts/CruyffSansMono-Regular.woff') format('woff'),
      url('/fonts/CruyffSansMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans';
    src: url('/fonts/CruyffSans-Bold.eot');
    src: local('Cruyff Sans Bold'), local('CruyffSans-Bold'),
      url('/fonts/CruyffSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CruyffSans-Bold.woff2') format('woff2'),
      url('/fonts/CruyffSans-Bold.woff') format('woff'),
      url('/fonts/CruyffSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans';
    src: url('/fonts/CruyffSans-Light.eot');
    src: local('Cruyff Sans Light'), local('CruyffSans-Light'),
      url('/fonts/CruyffSans-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CruyffSans-Light.woff2') format('woff2'),
      url('/fonts/CruyffSans-Light.woff') format('woff'),
      url('/fonts/CruyffSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Cruyff Sans';
    src: url('/fonts/CruyffSans-Thin.eot');
    src: local('Cruyff Sans Thin'), local('CruyffSans-Thin'),
      url('/fonts/CruyffSans-Thin.eot?#iefix') format('embedded-opentype'),
      url('/fonts/CruyffSans-Thin.woff2') format('woff2'),
      url('/fonts/CruyffSans-Thin.woff') format('woff'),
      url('/fonts/CruyffSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
`;
