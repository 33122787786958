/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import * as S from './styled'

import {ContainerGame} from '../../Atons/Container'
import Navbar from '../../Molecules/Navbar'

import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../context/ToastContext';

type Difficulty = 'easy' | 'normal' | 'hard' | 'veryHard'

const getNextDifficulty = (
  currentDifficulty: Difficulty,
  playerPerformance: number
): Difficulty => {
  const difficulties: Difficulty[] = [
    'easy',
    'normal',
  ]
  const currentIndex = difficulties.indexOf(currentDifficulty)

  if (playerPerformance > 70 && currentIndex < difficulties.length - 1) {
    return difficulties[currentIndex + 1]
  } else if (playerPerformance < 15 && currentIndex > 0) {
    return difficulties[currentIndex - 1]
  }

  return currentDifficulty
}

const GameTest: React.FC = () => {
  const [difficulty, setDifficulty] = useState<Difficulty>('normal')
  const [score, setScore] = useState(0)
  const [record, setRecord] = useState(0)
  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - 45,
    y: window.innerHeight / 2 - 45
  })
  const [stopY, setStopY] = useState(false)
  const [recordMode, setRecordMode] = useState(false)
  const [chances, setChances] = useState(3)
  const [playerPerformance, setPlayerPerformance] = useState<number>(50)

  const speedXRef = useRef(10)
  const speedYRef = useRef(0)

  const gameDivRef = useRef<HTMLDivElement | null>(null)
  const ballRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate();
  const { notifyWarning, notifySuccess } = useToast();

  const difficultySettings = useMemo(
    () => ({
      easy: {
        initialSpeedX: 5,
        initialSpeedY: 0,
        gravityMultiplier: 0.25,
        clickSpeedXDivider: 7,
        clickSpeedYDivider: 9,
        speedXReduction: 0.995,
        collisionSpeedXReduction: 1.6,
        maxSpeedY: -8
      },
      normal: {
        initialSpeedX: 10,
        initialSpeedY: 0,
        gravityMultiplier: 0.25,
        clickSpeedXDivider: 5,
        clickSpeedYDivider: 6,
        speedXReduction: 0.99,
        collisionSpeedXReduction: 1.6,
        maxSpeedY: -12
      },
      hard: {
        initialSpeedX: 15,
        initialSpeedY: 5,
        gravityMultiplier: 0.4,
        clickSpeedXDivider: 3,
        clickSpeedYDivider: 4,
        speedXReduction: 0.97,
        collisionSpeedXReduction: 1.3,
        maxSpeedY: -15
      },
      veryHard: {
        initialSpeedX: 20,
        initialSpeedY: 10,
        gravityMultiplier: 0.5,
        clickSpeedXDivider: 2,
        clickSpeedYDivider: 3,
        speedXReduction: 0.95,
        collisionSpeedXReduction: 1.1,
        maxSpeedY: -20
      }
    }),
    []
  )

  const {
    initialSpeedX,
    initialSpeedY,
    gravityMultiplier,
    clickSpeedXDivider,
    clickSpeedYDivider,
    speedXReduction,
    collisionSpeedXReduction,
    maxSpeedY
  } = difficultySettings[difficulty]

  useEffect(() => {
    const gameDiv = gameDivRef.current
    const scoreBoard = gameDiv?.querySelector('#scoreBoard') as HTMLDivElement
    const recordBoard = gameDiv?.querySelector('#recordBoard') as HTMLDivElement
    const loaderScreen = gameDiv?.querySelector('#loader') as HTMLDivElement

    speedXRef.current = initialSpeedX
    speedYRef.current = initialSpeedY

    scoreBoard.innerText = '0'
    //recordBoard.innerText = '0';

    setTimeout(() => {
      if (gameDiv) {
        gameDiv.style.opacity = '1'
      }
      if (loaderScreen) {
        loaderScreen.style.opacity = '0'
        setTimeout(() => {
          loaderScreen.style.display = 'none'
        }, 500)
      }
    }, 500)
  }, [initialSpeedX, initialSpeedY])

  useEffect(() => {
    const interval = setInterval(() => {
      const newSpeedY = speedYRef.current
      const newSpeedX = speedXRef.current
      let { x: newPositionX, y: newPositionY } = position

      // Ajusta a gravidade baseada em positionY
      const gravityForce = Math.max(1, newPositionY / 100) // Ajuste conforme necessário

      if (!stopY) {
        speedYRef.current -= gravityMultiplier * gravityForce // Simula a gravidade com força variável
        newPositionY += speedYRef.current // Movimento vertical da bola
      }

      // Lógica de movimento horizontal suave
      if (!stopY) {
        newPositionX += newSpeedX // Movimento horizontal padrão
      } else {
        // Se a bola parou, movimento horizontal suavizado
        newPositionX += Math.sign(newSpeedX) * 1.6
        speedXRef.current *= speedXReduction // Reduz a velocidade ao parar
      }

      // Lógica de colisão nas laterais
      const gameWidth = gameDivRef.current?.clientWidth || 0
      if (newPositionX <= 0) {
        newPositionX = 0
        speedXRef.current = Math.abs(newSpeedX) / collisionSpeedXReduction
      }
      if (newPositionX + 90 >= gameWidth) {
        newPositionX = gameWidth - 90
        speedXRef.current = -Math.abs(newSpeedX) / collisionSpeedXReduction
      }

      // Lógica de colisão no topo
      if (newPositionY <= 0 && !stopY) {
        newPositionY = 0
        setStopY(true)
        speedYRef.current = 0
        if (score > 0) {
          setScore(0)
          setRecordMode(false)
          setChances((prevChances) => {
            const newChances = prevChances - 1
            if(newChances > 0){
              notifyWarning(`Tente de Novo você ainda possui chances`)
            }
            
            if (newChances === 0) {
              notifySuccess(`A partida treino acabou`)
              navigate("/jogue-agora")
            }
            return newChances
          })
          const newPerformance = Math.random() * 100
          setPlayerPerformance(newPerformance)
          setDifficulty((prevDifficulty) =>
            getNextDifficulty(prevDifficulty, newPerformance)
          )
        }
      }

      // Atualiza as posições
      setPosition({ x: newPositionX, y: newPositionY })
      speedYRef.current = Math.max(speedYRef.current, maxSpeedY) // Limita a velocidade máxima
    }, 20)

    return () => clearInterval(interval)
  }, [
    position,
    stopY,
    score,
    gravityMultiplier,
    speedXReduction,
    collisionSpeedXReduction,
    maxSpeedY
  ])

  const handleBallClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const ballRect = ballRef.current?.getBoundingClientRect()
      if (ballRect && chances > 0) {
        // Verifica se o jogador ainda tem chances
        const newSpeedX = (45 - event.nativeEvent.offsetX) / clickSpeedXDivider
        let newSpeedY = (event.nativeEvent.offsetY + 45) / clickSpeedYDivider

        if (newSpeedY > 1) {
          setStopY(false)
        } else {
          newSpeedY = 0
        }

        // Ajuste da posição Y para subir a bola
        const newPosY = position.y + newSpeedY
        setPosition((prevPosition) => ({
          ...prevPosition,
          y: newPosY
        }))

        // Aumenta a velocidade Y da bola para tornar o movimento mais fluido
        speedYRef.current += newSpeedY

        // Aumenta a velocidade X da bola para manter o movimento horizontal
        speedXRef.current += newSpeedX

        setScore((prevScore) => {
          const newScore = prevScore + 1
          setRecord((prevRecord) => Math.max(newScore, prevRecord))
          if (newScore > prevScore) {
            setRecordMode(true)
          }
          return newScore
        })

        if (stopY) {
          setStopY(false)
          speedYRef.current = 12
        } else {
          speedYRef.current += 5
        }
      }
    },
    [clickSpeedXDivider, clickSpeedYDivider, position, stopY, chances]
  )

  return (
    <ContainerGame fixed={'true'} isgame={'true'}>
      <Navbar />
      <S.GameDiv id="game-div" ref={gameDivRef}>
        <S.BallShadow
          id="ballShadow"
          style={{
            left: `${position.x + 45}px`,
            filter: `blur(${Math.min((position.y + 80) / 40, 3)}px)`,
            width: `${Math.min((position.y + 225) / 5, 200)}px`
          }}
        />
        <S.RecordBoard
          id="scoreBoard"
          className={recordMode ? 'recordmode' : ''}
          style={{ pointerEvents: 'none' }}
        >
          {score}
        </S.RecordBoard>
        <S.ContainerUI>
          <S.ChancesBoard id="chancesBoard" style={{ pointerEvents: 'none' }}>
            <img src={"./game/football-icon.png"} alt="" />
            {chances}x
          </S.ChancesBoard>
        </S.ContainerUI>
        <S.Ball
          id="ball"
          ref={ballRef}
          style={{ bottom: `${position.y}px`, left: `${position.x}px` }}
          onClick={handleBallClick}
        >
          <S.InnerBall
            id="innerBall"
            style={{
              transform: `rotate(${position.x * (360 / 282.74)}deg)`
            }}
          />
        </S.Ball>
      </S.GameDiv>
      <S.BottomGame>
        <S.LinkHome to={'/jogue-agora'}>
          Clique aqui para começar agora e ganhe seus prêmios!
        </S.LinkHome>
      </S.BottomGame>
    </ContainerGame>
  )
}

export default GameTest
