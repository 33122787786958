import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

const PrivateRoute: React.FC = () => {
  const location = useLocation();
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
