/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect
} from 'react';
import { useApi } from '../api/api';
import { Match } from './types/Match';
import useEncryption from '../hooks/useEncryption';
import { useUser } from './UserContext';

type LoadMatchesResponse = {
  totalPages: number;
  matches: Match[];
};

interface MatchContextData {
  matches: Match | null;
  matchesAll: Match[] | null;
  loadingMatches: boolean;
  error: any;
  loadMatches: (userId?: string, page?: number) => Promise<LoadMatchesResponse>;
  getMatchById: (id: string | number) => Promise<Match | null>;
  createMatch: (matchData: Partial<Match>) => Promise<Match | null>;
  finalizeMatch: (
    id: string | number,
    status: boolean,
    value: number
  ) => Promise<boolean>;
}

interface MatchProviderProps {
  children: ReactNode;
}

export const MatchContext = createContext<MatchContextData>(
  {} as MatchContextData
);

export const MatchProvider: React.FC<MatchProviderProps> = ({ children }) => {
  const [matches, setMatches] = useState<Match | null>(null);
  const [matchesAll, setMatchesAll] = useState<Match[] | null>(null);
  const [loadingMatches, setLoadingMatches] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const { apiAuth } = useApi();
  const { decryptData } = useEncryption();
  const { loadUser } = useUser();

  const getToken = () => {
    const encryptedToken = localStorage.getItem('token');
    return encryptedToken ? decryptData(encryptedToken) : null;
  };

  const getUserId = () => {
    const encryptedUserId = localStorage.getItem('userId');
    return encryptedUserId ? decryptData(encryptedUserId) : null;
  };

  const loadMatches = async (
    userId?: string,
    page = 1
  ): Promise<LoadMatchesResponse> => {
    const token = getToken();
    if (!token) {
      // Retornar um objeto com valores padrão em vez de null
      return {
        totalPages: 0,
        matches: []
      };
    }

    try {
      setLoadingMatches(true);
      const queryParams = new URLSearchParams();
      if (userId) queryParams.append('userId', userId);
      queryParams.append('page', page.toString());

      const response = await apiAuth.get(`/matches?${queryParams.toString()}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setMatchesAll(response.data.matches);
      setMatches(response.data.matches);

      return {
        totalPages: response.data.totalPages,
        matches: response.data.matches
      };
    } catch (err: any) {
      console.error('Error loading matches', err);
      setError(err);
      // Retornar um objeto com valores padrão em vez de null
      return {
        totalPages: 0,
        matches: []
      };
    } finally {
      setLoadingMatches(false);
    }
  };

  const getMatchById = async (id: string | number) => {
    const token = getToken();
    if (!token) return null;

    try {
      const response = await apiAuth.get(`/matches/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (err: any) {
      console.error('Error fetching match by ID', err);
      setError(err);
      return null;
    }
  };

  const createMatch = async (matchData: Partial<Match>) => {
    const token = getToken();
    if (!token) return null;

    try {
      const response = await apiAuth.post('/matches', matchData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const newMatch = response.data;

      // Atualiza o estado com a nova partida criada
      setMatches(() => newMatch);
      return newMatch;
    } catch (err: any) {
      console.error('Error creating match', err);
      setError(err);
      return null;
    }
  };

  const finalizeMatch = async (
    id: string | number,
    status: boolean,
    value: number
  ) => {
    const token = getToken();
    const userId = getUserId();

    if (!token && !userId) return false;

    try {
      // Certifique-se de que o matchId é um número antes de enviá-lo
      const matchId = typeof id === 'string' ? parseInt(id, 10) : id;

      if (isNaN(matchId)) {
        throw new Error('Invalid matchId: must be a number');
      }

      await apiAuth.post(
        `/matches/finalize`,
        { matchId, status, userId, value },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setMatches(() => null);
      if (userId && token) {
        loadUser(userId, token);
      }
      return true;
    } catch (err: any) {
      console.error('Error finalizing match', err);
      setError(err);
      return false;
    }
  };

  useEffect(() => {
    const id = getUserId();
    const token = getToken();

    if (id && token) {
      loadMatches(id);
    }
  }, []);

  return (
    <MatchContext.Provider
      value={{
        matches,
        matchesAll,
        loadingMatches,
        error,
        loadMatches,
        getMatchById,
        createMatch,
        finalizeMatch
      }}
    >
      {children}
    </MatchContext.Provider>
  );
};

export function useMatch(): MatchContextData {
  const context = useContext(MatchContext);
  if (!context) {
    throw new Error('useMatch must be used within a MatchProvider');
  }
  return context;
}
