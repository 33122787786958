/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  color: ${(props) => props.theme.colors.primary};
  background-image: url('./images/background-home-web.png');
  background-size: cover;
  background-position: top center;
  overflow-x: hidden;

  position: fixed;
`;

export const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 122px);

  background-color: #00000090;
  box-shadow: ${(props) =>
    css`
      ${props.theme.colors.shadow} 0px 2px 8px
    `};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 0.5rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.2rem;
    span {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`;

export const ImgLogo = styled.img`
  width: 44%;
  max-width: 300px;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.black};

  color: ${(props) => props.theme.colors.white};
  text-align: center;

  a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const Title = styled.div`
  width: 100%;

  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  margin: 1rem 0;
`;

export const Balance = styled.div`
  width: 100%;

  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  margin: 0.5rem 0;

  span {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export const Form = styled.div`
  width: 100%;
  max-width: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContext = styled.div`
  width: 48%;
  margin-left: 2%;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

type SizeVariant = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

interface StyledInput {
  variant: SizeVariant;
}

const responsiveSizes = {
  xxs: { size: '8px', borderRadius: '1px', borderSize: '2px' },
  xs: { size: '10px', borderRadius: '1px', borderSize: '2px' },
  s: { size: '12px', borderRadius: '2px', borderSize: '2px' },
  m: { size: '14px', borderRadius: '3px', borderSize: '3px' },
  l: { size: '16px', borderRadius: '4px', borderSize: '3px' },
  xl: { size: '18px', borderRadius: '5px', borderSize: '3px' },
  xxl: { size: '20px', borderRadius: '5px', borderSize: '3px' },
  default: { size: '12px', borderRadius: '2px', borderSize: '2px' }
};

const getResponsiveValue = (variant: SizeVariant) => {
  return responsiveSizes[variant] || responsiveSizes['default'];
};

const commonStyles = css<StyledInput>`
  appearance: none;
  height: ${(props) => getResponsiveValue(props.variant).size};
  width: ${(props) => getResponsiveValue(props.variant).size};
  border-radius: ${(props) => getResponsiveValue(props.variant).borderRadius};
  position: relative;
  background: ${(props) => props.theme.colors.white};
  border: 2px solid ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background: ${(props) =>
      props.theme.colors.secondary}; /* Fundo preto quando checado */
    border-color: ${(props) =>
      props.theme.colors.white}; /* Borda preta quando checado */
  }
  &:checked:before {
    background: ${(props) =>
      props.theme.colors.black}; /* Fundo preto quando checado */
  }

  &:checked:after {
    content: '✔';
    color: ${(props) => props.theme.colors.black}; /* Cor do checkmark */
    font-size: 0.7rem;
  }
`;

export const Check = styled.input.attrs({ type: 'checkbox' })<StyledInput>`
  ${commonStyles}
`;

export const Radio = styled.input.attrs({ type: 'radio' })<StyledInput>`
  ${commonStyles}
  border-radius: 50%;
`;

export const OptionContext = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Plays = styled.div`
  width: 100%;

  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  margin: 0.5rem 0;

  span {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const BetLink = styled.button`
  min-width: 300px;
  min-height: 60px;
  display: inline-flex;
  font-family: 'Cruyff Sans', sans-serif;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.3px;
  color: #000;
  background: ${(props) => props.theme.colors.secondary};
  border: none;
  border-radius: 1000px;
  box-shadow: 0px 0px 24px ${(props) => props.theme.colors.secondary};
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  margin: 1.2rem 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;

  &::before {
    content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    min-height: calc(60px + 12px);
    border: 6px solid ${(props) => props.theme.colors.white};
    box-shadow: 0 0 30px ${(props) => props.theme.colors.secondary};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
  }

  &:hover,
  &:focus {
    color: #000;
    transform: translateY(-6px);
  }

  &:hover::before,
  &:focus::before {
    opacity: 1;
  }

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 6px solid ${(props) => props.theme.colors.secondary};
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }

  &:hover::after,
  &:focus::after {
    animation: none;
    display: none;
  }

  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }
`;

export const TestLink = styled(Link)`
  min-width: 300px;
  min-height: 48px;
  display: inline-flex;
  font-family: 'Cruyff Sans', sans-serif;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.3px;
  color: #000;
  background: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 1000px;
  box-shadow: 0px 0px 24px ${(props) => props.theme.colors.primary};
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  margin: 1.2rem 0;
  text-decoration: none;
  font-weight: 800;

  &:hover {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 0px 24px ${(props) => props.theme.colors.primary};
  }
`;
