/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { HiShieldExclamation, HiShieldCheck } from 'react-icons/hi';
import * as S from './styled';
import { useMatch } from '../../../context/MatchContext';
import { useUser } from '../../../context/UserContext';
import { Match } from '../../../context/types/Match';

const FinancialTable: React.FC = () => {
  const { loadMatches, matchesAll, loadingMatches } = useMatch();
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1); // Novo estado para armazenar o número total de páginas
  const { getUserId } = useUser();
  const userId = getUserId();

  useEffect(() => {
    if (userId && page) {
      loadMatches(userId, page).then((response) => {
        if (response) {
          setTotalPages(response.totalPages);
        }
      });
    }
  }, [userId, page]);

  return (
    <>
      <S.Header>
        <S.Title>Histórico de Jogadas</S.Title>
      </S.Header>
      {loadingMatches ? (
        <div>Carregando...</div>
      ) : matchesAll && matchesAll.length > 0 ? (
        <S.TableContainer>
          <S.Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Valor</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {matchesAll.map((match: Match) => (
                <tr key={match.id}>
                  <td>{match.id}</td>
                  <td>R$ {match.bet.toFixed(2)}</td>
                  <td>
                    {match.status ? (
                      <>
                        <HiShieldCheck /> Win
                      </>
                    ) : (
                      <>
                        <HiShieldExclamation /> Loss
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </S.Table>
          <S.Pagination>
            <S.PageButton
              isActive={false}
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              Anterior
            </S.PageButton>
            <S.PageButton
              isActive={false}
              onClick={() => setPage(page + 1)}
              disabled={page === totalPages}
            >
              Próximo
            </S.PageButton>
          </S.Pagination>
        </S.TableContainer>
      ) : (
        <div>Nenhuma partida encontrada!</div>
      )}
    </>
  );
};

export default FinancialTable;
