import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Estilos para o componente Timer
const TimerContainer = styled.div`
  width: 100%;
  height: 64px;

  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Função para formatar o tempo restante
const formatTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
    2,
    '0'
  )}`;
};

const Timer: React.FC<{ onTimeout: () => void }> = ({ onTimeout }) => {
  const [timeLeft, setTimeLeft] = useState<number>(300); // 5 minutos em segundos

  useEffect(() => {
    if (timeLeft <= 0) {
      onTimeout(); // Aciona a função quando o tempo acabar
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft, onTimeout]);

  return <TimerContainer>{formatTime(timeLeft)}</TimerContainer>;
};

export default Timer;
