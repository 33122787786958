/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import {
  login as loginService,
  createUser as createUserService,
  requestPasswordReset as requestPasswordResetService,
  resetPassword as resetPasswordService,
  confirmEmail as confirmEmailService
} from '../api/authService';
import { useToast } from './ToastContext';
import useNavigateWithLoading from '../hooks/useNavigateWithLoading';
import { useCsrf } from './CsrfContext';
import { useUser } from './UserContext';
import useEncryption from '../hooks/useEncryption';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import axios from 'axios';

interface AuthContextData {
  token: string | null;
  loading: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  createUser: (
    username: string,
    email: string,
    password: string,
    indicated: string,
    document: string,
    phone: string
  ) => Promise<void>;
  requestPasswordReset: (email: string) => Promise<void>;
  resetPassword: (
    token: string | undefined,
    newPassword: string
  ) => Promise<void>;
  confirmEmail: (token: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { notifySuccess, notifyError } = useToast();
  const navigateWithLoading = useNavigateWithLoading();
  const { refreshCsrfToken } = useCsrf();
  const { encryptData, decryptData } = useEncryption();
  const { loadUser, setUser } = useUser();

  const saveToken = (token: string, refreshToken: string, userId: string) => {
    const encryptedToken = encryptData(token);
    const encryptedRefreshToken = encryptData(refreshToken);
    const encryptedUserId = encryptData(userId);

    localStorage.setItem('token', encryptedToken);
    localStorage.setItem('refreshToken', encryptedRefreshToken);
    localStorage.setItem('userId', encryptedUserId);

    setToken(token);
  };

  const getToken = () => {
    const encryptedToken = localStorage.getItem('token');
    return encryptedToken ? decryptData(encryptedToken) : null;
  };

  const getRefreshToken = () => {
    const encryptedRefreshToken = localStorage.getItem('refreshToken');
    return encryptedRefreshToken ? decryptData(encryptedRefreshToken) : null;
  };

  const getUserId = () => {
    const encryptedUserId = localStorage.getItem('userId');
    return encryptedUserId ? decryptData(encryptedUserId) : null;
  };

  const login = async (username: string, password: string) => {
    try {
      setLoading(true);
      const data = await loginService(username, password);
      saveToken(data.token, data.refreshToken, data.user.userId);
      await loadUser(data.user.userId, data.token);
      notifySuccess('Login realizado com sucesso!');
    } catch (error: any) {
      notifyError(error.response?.data?.message || 'Erro ao realizar login.');
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (
    username: string,
    email: string,
    password: string,
    indicated: string,
    document: string,
    phone: string
  ) => {
    try {
      setLoading(true);

      // Chama o serviço de criação de usuário e recebe o token e dados de usuário
      const data = await createUserService(
        username,
        email,
        password,
        indicated,
        document,
        phone
      );

      // Salvar o token e refresh token no localStorage e no estado
      saveToken(data.token, data.refreshToken, data.user.userId);

      // Carregar o usuário no estado
      await loadUser(data.user.userId, data.token);

      // Notificar sucesso e redirecionar para a página de depósito
      notifySuccess('Usuário cadastrado e logado com sucesso!');
      navigateWithLoading('/deposito');
    } catch (error: any) {
      notifyError(error.response?.data?.message || 'Erro ao criar usuário.');
    } finally {
      setLoading(false);
    }
  };

  const requestPasswordReset = async (email: string) => {
    try {
      setLoading(true);
      await requestPasswordResetService(email);
      notifySuccess('Solicitação de redefinição de senha enviada!');
    } catch (error: any) {
      notifyError(
        error.response?.data?.message ||
          'Erro ao solicitar redefinição de senha.'
      );
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (
    token: string | undefined,
    newPassword: string
  ) => {
    try {
      setLoading(true);
      if (token) {
        await resetPasswordService(token, newPassword);
        notifySuccess('Senha redefinida com sucesso!');
        navigateWithLoading('/login');
      }
    } catch (error: any) {
      notifyError(error.response?.data?.message || 'Erro ao redefinir senha.');
    } finally {
      setLoading(false);
    }
  };

  const confirmEmail = async (token: string) => {
    try {
      setLoading(true);
      await confirmEmailService(token);
      notifySuccess('Email confirmado com sucesso!');
      navigateWithLoading('/login');
    } catch (error: any) {
      notifyError(error.response?.data?.message || 'Erro ao confirmar email.');
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken'); // Remover o refresh token ao fazer logout
    localStorage.removeItem('userId');
    setToken(null);
    navigateWithLoading('/login');
    notifySuccess('Logout realizado com sucesso!');
    setUser(null);
  };

  const fetchCsrfToken = async () => {
    try {
      await refreshCsrfToken();
    } catch (error) {
      console.error('Error fetching CSRF token', error);
    }
  };

  const checkToken = () => {
    const token = getToken();
    if (token) {
      try {
        const decodedToken: any = JSON.parse(atob(token.split('.')[1]));
        if (decodedToken.exp * 1000 < Date.now()) {
          refreshAuthToken(); // Atualizar o token de autenticação quando expirar
        } else {
          setToken(token);
        }
      } catch (error) {
        console.error('Erro ao decodificar o token', error);
        setUser(null);
        logout();
      }
    }
  };

  const refreshAuthToken = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      logout();
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/refresh-token`,
        { refreshToken }
      );
      const { token: newToken } = response.data;
      setToken(newToken);
      const encryptedToken = encryptData(newToken);
      localStorage.setItem('token', encryptedToken);
      notifySuccess('Token atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar o token', error);
      notifyError('Erro ao atualizar o token');
      setUser(null);
      logout();
    }
  };

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    checkToken();
    const userId = getUserId();
    const token = getToken();
    if (token && userId) {
      loadUser(userId, token);
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        loading,
        login,
        logout,
        createUser,
        requestPasswordReset,
        resetPassword,
        confirmEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
}
