import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode
} from 'react';
import axios from 'axios';
import { useApi } from '../api/api'; // Corrected import

interface CsrfContextType {
  csrfToken: string | null;
  refreshCsrfToken: () => Promise<void>;
}

const CsrfContext = createContext<CsrfContextType | undefined>(undefined);

export const useCsrf = (): CsrfContextType => {
  const context = useContext(CsrfContext);
  if (context === undefined) {
    throw new Error('useCsrf must be used within a CsrfProvider');
  }
  return context;
};

export const CsrfProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const [csrfToken, setCsrfTokenState] = useState<string | null>(null);
  const { setCsrfToken: setLocalStorageCsrfToken } = useApi(); // Use the useApi hook

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get<{ csrfToken: string }>(
        `${API_URL}/csrf-token`,
        { withCredentials: true }
      );
      const token = response.data.csrfToken;
      setCsrfTokenState(token);
      setLocalStorageCsrfToken(token); // Store the token in localStorage
    } catch (error) {
      console.error('Error fetching CSRF token', error);
      setCsrfTokenState(null);
    }
  };

  const refreshCsrfToken = async () => {
    await fetchCsrfToken();
  };

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  return (
    <CsrfContext.Provider value={{ csrfToken, refreshCsrfToken }}>
      {children}
    </CsrfContext.Provider>
  );
};
