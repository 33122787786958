import React, { useRef } from 'react';
import styled from 'styled-components';
import { FaCopy } from 'react-icons/fa'; // Ícone de copiar do react-icons

// Estilos para o componente CopyInput
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input`
  padding: 8px 40px 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  flex: 1;
`;

const CopyIcon = styled(FaCopy)`
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #3498db;
  font-size: 1.2rem;
`;

const CopyInput: React.FC<{
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopyClick = async () => {
    if (inputRef.current) {
      try {
        await navigator.clipboard.writeText(inputRef.current.value);
        alert('Texto copiado para a área de transferência!'); // Mensagem de sucesso
      } catch (error) {
        alert('Falha ao copiar o texto.'); // Mensagem de erro
        console.error('Erro ao copiar:', error);
      }
    }
  };

  return (
    <InputContainer>
      <StyledInput
        ref={inputRef}
        type="text"
        value={value}
        onChange={onChange}
      />
      <CopyIcon onClick={handleCopyClick} />
    </InputContainer>
  );
};

export default CopyInput;
