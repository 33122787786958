import styled from 'styled-components';

export const Title = styled.div`
  width: 100%;

  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Cruyff Sans Expanded';

  margin: 1rem 0;
`;
