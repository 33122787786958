/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css, Interpolation } from 'styled-components'

type Media = {
  mobile: (
    ...args: [TemplateStringsArray, ...Interpolation<any>[]]
  ) => ReturnType<typeof css>
  tablet: (
    ...args: [TemplateStringsArray, ...Interpolation<any>[]]
  ) => ReturnType<typeof css>
  desktop: (
    ...args: [TemplateStringsArray, ...Interpolation<any>[]]
  ) => ReturnType<typeof css>
  largeDesktop: (
    ...args: [TemplateStringsArray, ...Interpolation<any>[]]
  ) => ReturnType<typeof css>
}

const media: Media = {
  mobile: (first, ...interpolations) => css`
    @media (min-width: ${(props) => props.theme.sizes.mobile}) {
      ${css(first, ...interpolations)};
    }
  `,
  tablet: (first, ...interpolations) => css`
    @media (min-width: ${(props) => props.theme.sizes.tablet}) {
      ${css(first, ...interpolations)};
    }
  `,
  desktop: (first, ...interpolations) => css`
    @media (min-width: ${(props) => props.theme.sizes.desktop}) {
      ${css(first, ...interpolations)};
    }
  `,
  largeDesktop: (first, ...interpolations) => css`
    @media (min-width: ${(props) => props.theme.sizes.largeDesktop}) {
      ${css(first, ...interpolations)};
    }
  `
}

export default media
