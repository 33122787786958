import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  padding-top: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};

  h2 {
    font-size: 1.8rem;
    width: 92%;
    margin: 1rem 0;
    font-weight: 800;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.primary};
  }

  p {
    width: 92%;
    margin: 1rem 0;
    font-weight: 800;
    text-transform: uppercase;
  }
`;
