/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect
} from 'react';
import { useApi } from '../api/api'; // Corrected import
import { User } from './types/User';
import useEncryption from '../hooks/useEncryption';

interface UserContextData {
  user: User | null;
  loadingUser: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  loadUser: (userId: string, token: string) => Promise<void>;
  error: any;
  indicatedUsers: any;
  getToken: () => string | null;
  getUserId: () => string | null;
  getIndicated: (userId: string, token: string) => Promise<void>;
}

interface UserProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserContextData>(
  {} as UserContextData
);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [indicatedUsers, setIndicatedUsers] = useState<User | null>(null);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const { encryptData, decryptData } = useEncryption();
  const { apiAuth } = useApi();

  const getToken = () => {
    const encryptedToken = localStorage.getItem('token');
    return encryptedToken ? decryptData(encryptedToken) : null;
  };

  const getRefreshToken = () => {
    const encryptedRefreshToken = localStorage.getItem('refreshToken');
    return encryptedRefreshToken ? decryptData(encryptedRefreshToken) : null;
  };

  const getUserId = () => {
    const encryptedUserId = localStorage.getItem('userId');
    return encryptedUserId ? decryptData(encryptedUserId) : null;
  };

  const getIndicated = async (userId: string, token: string) => {
    try {
      const response = await apiAuth.get(`/users/indicated/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIndicatedUsers(response.data);
    } catch (error) {
      console.error('Erro ao carregar usuários indicados', error);
      setError(error);
    }
  };

  const loadUser = async (userId: string, token: string) => {
    try {
      setLoadingUser(true);
      const response = await apiAuth.get(`/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUser(response.data);
    } catch (err: any) {
      console.error('Erro ao carregar usuário', err);
      setError(err);
    } finally {
      setLoadingUser(false);
    }
  };

  const refreshToken = async () => {
    try {
      const storedRefreshToken = localStorage.getItem('refreshToken');
      if (!storedRefreshToken) {
        throw new Error('Refresh token não encontrado');
      }
      const decryptedRefreshToken = decryptData(storedRefreshToken);

      const { data } = await apiAuth.post('/users/refresh-token', {
        refreshToken: decryptedRefreshToken
      });

      const encryptedToken = encryptData(data.token);
      const encryptedRefreshToken = encryptData(data.refreshToken);

      localStorage.setItem('token', encryptedToken);
      localStorage.setItem('refreshToken', encryptedRefreshToken);
    } catch (err: any) {
      console.error('Erro ao atualizar o token', err);
      setError(err);
    }
  };

  useEffect(() => {
    const token = getToken();
    const userId = getUserId();

    if (token && userId) {
      loadUser(userId, token);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 15 * 60 * 1000); // Atualiza o token a cada 15 minutos

    return () => clearInterval(interval);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        loadingUser,
        setUser,
        loadUser,
        error,
        indicatedUsers,
        getToken,
        getUserId,
        getIndicated
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUser(): UserContextData {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser deve ser usado dentro de um UserProvider');
  }
  return context;
}
