import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home';
import Login from './components/Pages/Login';
import Terms from './components/Pages/Termos';
import Painel from './components/Pages/Painel';
import Register from './components/Pages/Register';
import ConfirmEmail from './components/Pages/ConfirmEmail';
import ResetPassword from './components/Pages/ResetPassword';
import NewPassword from './components/Pages/NewPassword';
import MyAcc from './components/Pages/MyAcc';
import Deposit from './components/Pages/Deposit';
import Withdraw from './components/Pages/Withdraw';
import Affiliate from './components/Pages/Affiliate';
import PrivateRoute from './components/PrivateRoute';
import { useLoading } from './context/LoadingContext';
import LoadingSpinner from './components/Atons/LoadingSpinner';
import Game from './components/Pages/Game';
import GameTest from './components/Pages/Testar';
import OrdersPage from './components/Pages/OrderPage';
import FinancialPage from './components/Pages/Financial';

const AppRoutes: React.FC = () => {
  const { isLoading } = useLoading();

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/:indicator" element={<Register />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/confirm-email/" element={<ConfirmEmail />} />
        <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
        <Route path="/reset-password/" element={<ResetPassword />} />
        <Route path="/new-password/:token" element={<NewPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/perfil" element={<MyAcc />} />
          <Route path="/jogue-agora" element={<Painel />} />
          <Route path="/deposito" element={<Deposit />} />
          <Route path="/saque" element={<Withdraw />} />
          <Route path="/jogar" element={<Game />} />
          <Route path="/testar" element={<GameTest />} />
          <Route path="/financeiro" element={<OrdersPage />} />
          <Route path="/jogadas" element={<FinancialPage />} />
          <Route path="/compartilhar" element={<Affiliate />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
