/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ThemeProvider } from 'styled-components';

interface Theme {
  children: React.ReactNode;
  theme: any;
}

const Theme = ({ children, theme }: Theme) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
