/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import * as S from './styled';
import { Container } from '../../Atons/Container';
import Input from '../../Atons/Input';
import Button from '../../Atons/Button';
import Navbar from '../../Molecules/Navbar';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../context/ToastContext';
import { useNavigate } from 'react-router-dom';
import { useCsrf } from '../../../context/CsrfContext';

const Login = () => {
  const { loading, login } = useAuth();
  const { csrfToken, refreshCsrfToken } = useCsrf();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const { notifyError } = useToast();

  const handleClick = async () => {
    if (!csrfToken) {
      console.error('CSRF token is not available');
      return;
    }

    try {
      await login(email, password);
      navigate('/jogue-agora');
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        await refreshCsrfToken();
      }
      notifyError(error.response.data.message);
    }
  };

  return (
    <Container fixed={'true'}>
      <Navbar />
      <S.ImgLogo src={'./logo.png'} alt="" />
      <S.Wrapper>
        <h2>Login</h2>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autocomplete="email"
        />
        <Input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autocomplete="current-password"
        />
        <S.WrapperLinks>
          <S.LinkResetPassword to={'/reset-password'}>
            Esqueceu sua senha ?
          </S.LinkResetPassword>
          <S.LinkResetPassword to={'/register'}>
            Cadastre se
          </S.LinkResetPassword>
        </S.WrapperLinks>
        <Button
          onClick={handleClick}
          variant="primary"
          loading={loading ? 'true' : undefined}
        >
          Entrar
        </Button>
      </S.Wrapper>
    </Container>
  );
};

export default Login;
