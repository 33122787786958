/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useUser } from '../../../context/UserContext';

import { Container } from '../../Atons/Container';
import Navbar from '../../Molecules/Navbar';
import CopyInput from '../../Atons/CopyInput';
import IndicatedUsersTable from '../../Molecules/IndicatedUsersTable/IndicatedUsersTable';
import Title from '../../Molecules/Title';

import * as S from './styled';

const Affiliate = () => {
  const { user, indicatedUsers, getIndicated, getToken, getUserId } = useUser();
  const [page, setPage] = useState<number>(1);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const id = getUserId();
    const token = getToken();
    if (id && token) {
      getIndicated(id, token);
    }
  }, []);

  return (
    <Container>
      <Navbar />
      <S.Wrapper>
        <S.Content>
          <Title text="Compartilhar" />
          <S.WrapperInput>
            <span>Seu Link</span>
            <CopyInput
              value={`${process.env.REACT_APP_URL}register/${user?.username}`}
            />
          </S.WrapperInput>
          {indicatedUsers ? (
            <IndicatedUsersTable
              currentPage={indicatedUsers.currentPage}
              indicatedUsers={indicatedUsers.indicatedUsers}
              totalDeposited={indicatedUsers.totalDeposited}
              totalIndicatedUsers={indicatedUsers.totalIndicatedUsers}
              totalNotDeposited={indicatedUsers.totalNotDeposited}
              totalPages={indicatedUsers.totalPages}
              onPageChange={handlePageChange}
            />
          ) : (
            <p>Loading...</p>
          )}
        </S.Content>
      </S.Wrapper>
    </Container>
  );
};

export default Affiliate;
