export interface Order {
  id: number;
  userId: string;
  amount: number;
  status: OrderStatus;
  paymentStatus?: string;
  type: 'PAYMENT' | 'TRANSFER';
  approvalStatus: 'PENDING' | 'APPROVED' | 'REJECTED';
  paymentOrdersCount?: number;
  transferOrdersCount?: number;
  createdAt: string;
  updatedAt: string;
}

export enum OrderStatus {
  All = '',
  Pending = 'PENDING',
  PaidOut = 'PAID_OUT',
  PixCashOut = 'PIX_CASHOUT',
  Chargeback = 'CHARGEBACK',
  Cancelled = 'CANCELED',
  Completed = 'COMPLETED'
}

export enum OrderType {
  TRANSFER = 'Saque',
  PAYMENT = 'Deposito'
}
