/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components'

interface ContainerProps {
  src: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.colors.primary};
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: top center;
  overflow-x: hidden;
  position: fixed;
`

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 50%;
    margin: 1rem 0;
  }
`

export const Wrapper = styled.div`
  width: 92%;
  max-width: 360px;
  min-height: 244.59px;
  padding: 0.5rem;

  background-color: #00000090;
  box-shadow: ${(props) =>
    css`
      ${props.theme.colors.shadow} 0px 2px 8px
    `};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.primary};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 2rem;
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    font-weight: 800;
  }

  p {
    color: ${(props) => props.theme.colors.white};
  }
`
export const ImgLogo = styled.img`
  width: 44%;
  max-width: 300px;
  margin-bottom: 1rem;
`
