/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useReducer, useContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Order } from './types/Orders';

interface State {
  orders: Order[];
  loading: boolean;
  paymentStatus?: any;
  totalOrders: number; // Total de ordens para paginação
}

const initialState: State = {
  orders: [],
  loading: false,
  paymentStatus: null,
  totalOrders: 0 // Inicialize com 0
};

const OrderContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
  setLoading: (loading: boolean) => void;
  setPaymentStatus: (status: any) => void;
  setTotalOrders: (total: number) => void; // Adicione este setter
}>({
  state: initialState,
  dispatch: () => null,
  setLoading: () => {},
  setPaymentStatus: () => {},
  setTotalOrders: () => {} // Adicione este setter
});

const orderReducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'SET_ORDERS':
      return {
        ...state,
        orders: action.payload.orders,
        totalOrders: action.payload.totalOrders
      };
    case 'ADD_ORDER':
      return { ...state, orders: [...state.orders, action.payload] };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'UPDATE_PAYMENT_STATUS':
      return { ...state, paymentStatus: action.payload };
    case 'SET_TOTAL_ORDERS':
      return { ...state, totalOrders: action.payload };
    default:
      return state;
  }
};

interface OrderProviderProps {
  children: ReactNode;
}

export const OrderProvider: React.FC<OrderProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(orderReducer, initialState);

  const setLoading = (loading: boolean) => {
    dispatch({ type: 'SET_LOADING', payload: loading });
  };

  const setPaymentStatus = (status: any) => {
    dispatch({ type: 'UPDATE_PAYMENT_STATUS', payload: status });
  };

  const setTotalOrders = (total: number) => {
    dispatch({ type: 'SET_TOTAL_ORDERS', payload: total });
  };

  return (
    <OrderContext.Provider
      value={{ state, dispatch, setLoading, setPaymentStatus, setTotalOrders }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useOrderContext = () => useContext(OrderContext);
