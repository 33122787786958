/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import * as S from './styled'

import { Container } from '../../Atons/Container'
import Navbar from '../../Molecules/Navbar'
import { ModalGame } from '../../Organisms/ModalGame'
import Button from '../../Atons/Button'

import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../context/ToastContext';
import { useMatch } from '../../../context/MatchContext';
import { useUser } from '../../../context/UserContext';
import { useAudio } from '../../../context/AudioContext';

type Difficulty = 'easy' | 'normal' | 'hard' | 'veryHard'

type StageGame = "start" | "play" | "upsell" | "downsell"

const getNextDifficulty = (
  currentDifficulty: Difficulty,
  playerPerformance: number
): Difficulty => {
  const difficulties: Difficulty[] = [
    'normal',
    'normal',
    'hard',
    'hard',
    'hard',
    'hard',
    'veryHard',
    'veryHard',
    'veryHard',
    'veryHard',
  ]
  const currentIndex = difficulties.indexOf(currentDifficulty)

  if (playerPerformance > 70 && currentIndex < difficulties.length - 1) {
    return difficulties[currentIndex + 1]
  } else if (playerPerformance < 15 && currentIndex > 0) {
    return difficulties[currentIndex - 1]
  }

  return currentDifficulty
}

const Game: React.FC = () => {
  const [difficulty, setDifficulty] = useState<Difficulty>('normal')
  const [score, setScore] = useState(0)
  const [record, setRecord] = useState(0)
  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - 45,
    y: window.innerHeight / 2 - 45
  })
  const [stopY, setStopY] = useState(false)
  const [recordMode, setRecordMode] = useState(false)
  const [chances, setChances] = useState(3)
  const [playerPerformance, setPlayerPerformance] = useState<number>(50)

  const speedXRef = useRef(10)
  const speedYRef = useRef(0)

  const gameDivRef = useRef<HTMLDivElement | null>(null)
  const ballRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate();
  const { notifyWarning, notifySuccess } = useToast();
  const { matches, finalizeMatch } = useMatch();
  const { user, getToken, getUserId, loadUser } = useUser();
  const [bet, setBet] = useState(0)
  const [lastBet, setLastBet] = useState(0)
  const [showModal, setShowModal] = useState(false);
  const [win, setWin] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(10);
  const { isMuted, audioFormat } = useAudio();
  const backgroundAudioRef = useRef<HTMLAudioElement | null>(null);
  const effectAudioRef = useRef<HTMLAudioElement | null>(null);
  const [stage, setStage] = useState<StageGame>("start")

  const token = getToken();
  const userId = getUserId();

  useEffect(() => {
    const handleUserInteraction = () => {
      if (backgroundAudioRef.current) {
        backgroundAudioRef.current.muted = isMuted;
        if (!isMuted) {
          backgroundAudioRef.current.play().catch((error) => {
            console.error('Error playing background audio:', error);
          });
        } else {
          backgroundAudioRef.current.pause();
        }
      }

      // Remova o listener de evento após a primeira interação
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };

    // Adiciona listeners para capturar a primeira interação do usuário
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);

    return () => {
      // Limpa os listeners no caso do componente ser desmontado antes da interação
      document.removeEventListener('click', handleUserInteraction);
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, [isMuted]);

  const playEffectSound = () => {
    if (effectAudioRef.current) {
      // Reinicia o som se já estiver tocando
      if (!effectAudioRef.current.paused) {
        effectAudioRef.current.currentTime = 0;
      }
      // Toca o som se não estiver mudo
      if (!isMuted) {
        effectAudioRef.current.play();
      }
    }
  };

  useEffect(() => {
    if(matches === null || !user){
      navigate("/jogue-agora")
    }
    if(matches){
      setBet(matches.bet)
    }
  }, [])

  const difficultySettings = useMemo(
    () => ({
      easy: {
        initialSpeedX: 5,
        initialSpeedY: 0,
        gravityMultiplier: 0.25,
        clickSpeedXDivider: 7,
        clickSpeedYDivider: 9,
        speedXReduction: 0.995,
        collisionSpeedXReduction: 1.6,
        maxSpeedY: -8
      },
      normal: {
        initialSpeedX: 15,
        initialSpeedY: 5,
        gravityMultiplier: 0.4,
        clickSpeedXDivider: 3,
        clickSpeedYDivider: 4,
        speedXReduction: 0.97,
        collisionSpeedXReduction: 1.3,
        maxSpeedY: -15
      },
      hard: {
        initialSpeedX: 20,
        initialSpeedY: 10,
        gravityMultiplier: 0.5,
        clickSpeedXDivider: 2,
        clickSpeedYDivider: 3,
        speedXReduction: 0.95,
        collisionSpeedXReduction: 1.1,
        maxSpeedY: -20
      },
      veryHard: {
        initialSpeedX: 30,
        initialSpeedY: 15,
        gravityMultiplier: 0.2,
        clickSpeedXDivider: 1.1,
        clickSpeedYDivider: 1.8,
        speedXReduction: 0.90,
        collisionSpeedXReduction: 1.1,
        maxSpeedY: -25
      }
    }),
    []
  )

  const {
    initialSpeedX,
    initialSpeedY,
    gravityMultiplier,
    clickSpeedXDivider,
    clickSpeedYDivider,
    speedXReduction,
    collisionSpeedXReduction,
    maxSpeedY
  } = difficultySettings[difficulty]

  useEffect(() => {
    const gameDiv = gameDivRef.current
    const scoreBoard = gameDiv?.querySelector('#scoreBoard') as HTMLDivElement
    const recordBoard = gameDiv?.querySelector('#recordBoard') as HTMLDivElement
    const loaderScreen = gameDiv?.querySelector('#loader') as HTMLDivElement

    speedXRef.current = initialSpeedX
    speedYRef.current = initialSpeedY

    scoreBoard.innerText = '0'
    //recordBoard.innerText = '0';

    setTimeout(() => {
      if (gameDiv) {
        gameDiv.style.opacity = '1'
      }
      if (loaderScreen) {
        loaderScreen.style.opacity = '0'
        setTimeout(() => {
          loaderScreen.style.display = 'none'
        }, 500)
      }
    }, 500)
  }, [initialSpeedX, initialSpeedY])

  useEffect(() => {
    const interval = setInterval(() => {
      const newSpeedY = speedYRef.current
      const newSpeedX = speedXRef.current
      let { x: newPositionX, y: newPositionY } = position

      // Ajusta a gravidade baseada em positionY
      const gravityForce = Math.max(1, newPositionY / 100) // Ajuste conforme necessário

      if (!stopY) {
        speedYRef.current -= gravityMultiplier * gravityForce // Simula a gravidade com força variável
        newPositionY += speedYRef.current // Movimento vertical da bola
      }

      // Lógica de movimento horizontal suave
      if (!stopY) {
        newPositionX += newSpeedX // Movimento horizontal padrão
      } else {
        // Se a bola parou, movimento horizontal suavizado
        newPositionX += Math.sign(newSpeedX) * 1.6
        speedXRef.current *= speedXReduction // Reduz a velocidade ao parar
      }

      // Lógica de colisão nas laterais
      const gameWidth = gameDivRef.current?.clientWidth || 0
      if (newPositionX <= 0) {
        newPositionX = 0
        speedXRef.current = Math.abs(newSpeedX) / collisionSpeedXReduction
      }
      if (newPositionX + 90 >= gameWidth) {
        newPositionX = gameWidth - 90
        speedXRef.current = -Math.abs(newSpeedX) / collisionSpeedXReduction
      }

      // Lógica de colisão no topo
      if (newPositionY <= 0 && !stopY && matches) {
        newPositionY = 0
        setStopY(true)
        speedYRef.current = 0
        if (score > 0) {
          setScore(0)
          setRecordMode(false)
          setChances((prevChances) => {
            const newChances = prevChances - 1
            if(newChances > 0){
              handleTimeOver()
              setLastBet(bet)
            }
            
            if (newChances === 0 && matches) {
              finalizeMatch(matches.id, false, bet)
              notifySuccess(`A partida acabou`)
              navigate("/jogue-agora")
            }
            return newChances
          })
          const newPerformance = Math.random() * 100
          setPlayerPerformance(newPerformance)
          setDifficulty((prevDifficulty) =>
            getNextDifficulty(prevDifficulty, newPerformance)
          )
        }
      }

      // Atualiza as posições
      setPosition({ x: newPositionX, y: newPositionY })
      speedYRef.current = Math.max(speedYRef.current, maxSpeedY) // Limita a velocidade máxima
    }, 20)

    return () => clearInterval(interval)
  }, [
    position,
    stopY,
    score,
    gravityMultiplier,
    speedXReduction,
    collisionSpeedXReduction,
    maxSpeedY
  ])

  const handleBallClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const ballRect = ballRef.current?.getBoundingClientRect()
      if (ballRect && chances > 0) {
        // Verifica se o jogador ainda tem chances
        playEffectSound()
        const newSpeedX = (45 - event.nativeEvent.offsetX) / clickSpeedXDivider
        let newSpeedY = (event.nativeEvent.offsetY + 45) / clickSpeedYDivider

        if (newSpeedY > 1) {
          setStopY(false)
        } else {
          newSpeedY = 0
        }

        // Ajuste da posição Y para subir a bola
        const newPosY = position.y + newSpeedY
        setPosition((prevPosition) => ({
          ...prevPosition,
          y: newPosY
        }))

        // Aumenta a velocidade Y da bola para tornar o movimento mais fluido
        speedYRef.current += newSpeedY

        // Aumenta a velocidade X da bola para manter o movimento horizontal
        speedXRef.current += newSpeedX

        if(matches){
          setScore((prevScore) => {
            const newScore = prevScore + 1;
            const newBetvalue =  bet + matches.bet / 10;
            setBet(newBetvalue)
            setRecord((prevRecord) => Math.max(newScore, prevRecord))
            if (newScore > prevScore) {
              setRecordMode(true)
            }
            return newScore
          })
        }

        if (stopY) {
          setStopY(false)
          speedYRef.current = 12
        } else {
          speedYRef.current += 5
        }
      }
    },
    [clickSpeedXDivider, clickSpeedYDivider, position, stopY, chances]
  )

  const handleContinuePlay = () => {
    setBet(lastBet)
    setShowModal(false)
    setTimeLeft(10)
    if(matches){
      setBet(matches.bet)
    }
  }

  const handleTimeOver = () => {
    setShowModal(true)
    setTimeLeft(0)
    if(stage !== "downsell"){
      setBet(lastBet)
    }
  }

  const handleStarGame = () => {
    setShowModal(false)
    setTimeLeft(10)
    setStage("play")
  }

  const handleGameOver = () => {
    if(matches && bet){
      const value = bet.toFixed(2);
      finalizeMatch(matches.id, true, Number(value))
      
      if(userId && token){
        loadUser(userId, token)
      }

      navigate("/jogue-agora")
    }
  }

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  useEffect(() => {
    if (timeLeft <= 0) {


      if(chances > 0){
        handleTimeOver()
      }

      if(chances > 0 && score > 0){
        setTimeLeft(0)
        setWin(true)
        setStage("downsell")
        handleGameOver()
        setChances((prevChances) => {
          const newChances = prevChances - 1
          return newChances
        })
      }
      return;
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);
  
  useEffect(() => {
    if(stage === "start"){
      setShowModal(true);
    }
}, [stage]);
  

  return (
    <Container fixed={'true'} isgame={'true'}>
      <audio ref={backgroundAudioRef} src={`./sound/background.${audioFormat}`} loop />
      <audio ref={effectAudioRef} src={`./sound/soccer-ball-kick.${audioFormat}`} />
      <ModalGame showModal={showModal} setShowModal={handleContinuePlay}>
        {stage === "start" && (
          <>
            <S.Title>Start Game</S.Title>
            <S.Text>você tem {chances}x {chances > 1 ? "chances" : "chance"} de ganhar!</S.Text>
            <Button onClick={() => handleStarGame()} disabled={chances <= 0 && "true"}>Jogue Agora</Button>
          </>
        )}

        {stage === "play" && (
          <>
            <S.Title>Continuar Jogando</S.Title>
            <S.Text>você ainda tem {chances}x {chances > 1 ? "chances" : "chance"} de ganhar <span>R$ {lastBet.toFixed(2)} ou mais !</span></S.Text>
            <Button onClick={() => handleContinuePlay()} disabled={chances <= 0 && "true"}>Jogue Agora</Button>
          </>
        )}

        {stage === "downsell" && (
            <>
              <S.Title>Encerrar Partida</S.Title>
              <S.Text>você ganhou <span>R$ {bet.toFixed(2)} !</span></S.Text>
              {chances >= 1 ? (
                <>
                <Button onClick={handleContinuePlay}>Continuar o jogo!</Button>
                <Button onClick={handleGameOver}>Pegar o Premio</Button>
                </>
              ) : (
                <>
                  <Button onClick={handleGameOver}>Pegar o Premio</Button>
                </>
              )} 
            </>
          )}
      </ModalGame>
      <Navbar />
      <S.GameDiv id="game-div" ref={gameDivRef}>
        <S.BallShadow
          id="ballShadow"
          style={{
            left: `${position.x + 45}px`,
            filter: `blur(${Math.min((position.y + 80) / 40, 3)}px)`,
            width: `${Math.min((position.y + 225) / 5, 200)}px`
          }}
        />
        <S.RecordBoard
          id="scoreBoard"
          className={recordMode ? 'recordmode' : ''}
          style={{ pointerEvents: 'none' }}
        >
          {score}
        </S.RecordBoard>
        <S.ContainerUI>
          <S.ChancesBoard id="chancesBoard" style={{ pointerEvents: 'none' }}>
            {formatTime(timeLeft)}
          </S.ChancesBoard>
          <S.ChancesBoard id="chancesBoard" style={{ pointerEvents: 'none' }}>
            {chances}x
          </S.ChancesBoard>
          <S.ChancesBoard id="chancesBoard" style={{ pointerEvents: 'none' }}>
            R$ {bet.toFixed(2)}
          </S.ChancesBoard>
        </S.ContainerUI>
        <S.Ball
          id="ball"
          ref={ballRef}
          style={{ bottom: `${position.y}px`, left: `${position.x}px` }}
          onClick={handleBallClick}
        >
          <S.InnerBall
            id="innerBall"
            style={{
              transform: `rotate(${position.x * (360 / 282.74)}deg)`
            }}
          />
        </S.Ball>
      </S.GameDiv>
      <S.BottomGame></S.BottomGame>
    </Container>
  )
}

export default Game
